import React, { Component } from "react";
import '../../static/css/LoadingBar.css';


export default function LoadingBar(props) {
    return (
        
        <div className="loadbarContainer">
            <div className="loadwaveContainer">
                <div className="loadwave"></div>
                    <div className="wave">L</div>
                    <div className="wave">o</div>
                    <div className="wave">a</div>
                    <div className="wave">d</div>
                    <div className="wave">i</div>
                    <div className="wave">n</div>
                    <div className="wave">g</div>
                    <div className="wave">.</div>
                    <div className="wave">.</div>
                    <div className="wave">.</div>
                </div>
        </div>
        
    )
}