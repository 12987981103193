import React, { Component, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  addFavoriteRelease,
  deleteAllFavoriteReleases,
} from "../../store/slices/favoriteReleasesSlice"

export default function FavoriteReleases(props) {
  //favorites, localstorage
  const [showFavorites, setShowFavorites] = useState(false)

  //const favoriteReleases = useSelector((state) => state.favoriteReleases.list);

  const favoriteReleasesNew = useSelector((state) => state.favoriteReleasesNew)
  const { favoriteReleases, error } = favoriteReleasesNew
  const dispatch = useDispatch()

  function handleDeleteFavoriteReleases() {
    dispatch(deleteAllFavoriteReleases())
  }

  const downloadFavoriteReleases = () => {
    const storedFavorites = localStorage.getItem("favoriteReleases")
    const blob = new Blob([storedFavorites], { type: "text/plain" })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.style.display = "none"
    a.href = url
    a.download = "favoriteReleases.txt"
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  // const deleteFavorites = () => {
  //     const updatedFavorites = [];
  //     setFavorites(updatedFavorites);
  //     localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
  // };

  // const toggleFavorites = () =>{
  //     setShowFavorites(!showFavorites)
  // }
  // const downloadFavorites = () => {
  //     const storedFavorites = localStorage.getItem("favorites");
  //     const blob = new Blob([storedFavorites], { type: "text/plain" });
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.style.display = "none";
  //     a.href = url;
  //     a.download = "favorites.txt";
  //     document.body.appendChild(a);
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //     document.body.removeChild(a);
  // };

  return (
    <>
      {favoriteReleases && favoriteReleases[0] && (
        <>
          {favoriteReleases.map((favoriteRel, index) => (
            <div
              onClick={() => props.setReleaseId(favoriteRel)}
              className="favoriteRelease"
              key={index}
            >
              {favoriteRel.artist} - {favoriteRel.title}
            </div>
          ))}

          {/* <button onClick={handleDeleteFavoriteReleases}>DeleteAll</button>
                    <button onClick={downloadFavoriteReleases}>Download</button> */}
        </>
      )}
    </>
  )
}
