import { createSlice } from '@reduxjs/toolkit'

export const trackQueueSlice = createSlice({
  name: 'trackQueue',
  initialState: {
    videoList:[],
  },

  reducers: {
    addToQueue: (state, action) => {
        console.log(action.payload)
        state.videoList.push(action.payload)
    },
    reduceQueue: (state, action)=> {
        state.videoList.shift()
    },
    //takes index as action.payload to tell which item to remove
    removeItemFromQueue: (state,action)=> {
        state.videoList.splice(action.payload,1)
    },
    removeAllItemsFromQueue: (state,action)=>{
        state.videoList=[]
    }
  }
})

// Action creators are generated for each case reducer function
export const { addToQueue, reduceQueue, removeItemFromQueue,removeAllItemsFromQueue,

} = trackQueueSlice.actions

export default trackQueueSlice.reducer