import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

function DogaArtist({ artist }) {
  return (
    <div>
      <h1 className="dogaArtistName">{artist.name}</h1>
      {artist && artist.image && (
        <img className="dogaArtistMainImage" src={artist.image} />
      )}
      <p className="dogaArtistDescription">{artist.description}</p>
    </div>
  )
}

export default DogaArtist
