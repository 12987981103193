import React, { useEffect, useState } from "react"
import DogaArtistCard from "./DogaArtistCard"
import { useSelector, useDispatch } from "react-redux"

import { fetchDogaArtists } from "../../../store/slices/dogaMedia/dogaArtistsSlice"

function DogaArtistList() {
  const dispatch = useDispatch()
  const artistsLoaded = useSelector(
    (state) => state.dogaArtists.dogaArtistsLoaded
  )
  const dogaArtists = useSelector((state) => state.dogaArtists.dogaArtists)
  const [renderArtists, setRenderArtists] = useState(null)

  useEffect(() => {
    if (!artistsLoaded) {
      dispatch(fetchDogaArtists())
    }
  }, [artistsLoaded, dispatch])

  useEffect(() => {
    const newArtistsRender = dogaArtists.map((artist, index) => (
      <DogaArtistCard key={index} artist={artist} />
    ))
    setRenderArtists(newArtistsRender)
  }, [dogaArtists])

  const getDogaArtists = () => {
    return dogaArtists.map((artist, index) => (
      <DogaArtistCard id={index} artist={artist} />
    ))
  }

  return (
    <>
      <div className="dogaArtistListContainer">
        {renderArtists && <>{renderArtists}</>}
      </div>
    </>
  )
}

export default DogaArtistList
