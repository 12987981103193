import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  dogaArtists: [],
  dogaArtistsLoaded: false,
  error: null,
  currentArtist: {},
}

//GET ARTISTS
export const fetchDogaArtists = createAsyncThunk(
  "dogaArtists/fetchDogaArtists",
  async (_, { getState }) => {
    const state = getState()
    const response = await axios.get("/api/label/artists/", {
      headers: {},
    })
    console.log(response.data)
    return response.data
  }
)

export const dogaArtistsSlice = createSlice({
  name: "dogaArtists",
  initialState,
  reducers: {
    setCurrentArtist: (state, action) => {
      state.currentArtist = action.payload
    },
  },
  extraReducers: {
    [fetchDogaArtists.pending]: (state, action) => {
      state.status = "loading"
    },
    [fetchDogaArtists.fulfilled]: (state, action) => {
      state.status = "succeeded"
      state.dogaArtists = action.payload
      //set artists loaded to true so it doesn't reload in the front end again!
      state.dogaArtistsLoaded = true
    },
    [fetchDogaArtists.rejected]: (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    },
  },
})

export const { setCurrentArtist } = dogaArtistsSlice.actions

export default dogaArtistsSlice.reducer
