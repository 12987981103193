import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import DogaTracklist from "./DogaTracklist"
import DogaTrack from "./DogaTrack"

import { fetchDogaAlbumTracks } from "../../../store/slices/dogaMedia/dogaAlbumsSlice"

function DogaAlbum({ album }) {
  const dispatch = useDispatch()
  const currentAlbum = useSelector((state) => state.dogaAlbums.currentAlbum)
  const dogaAlbumTracks = useSelector(
    (state) => state.dogaAlbums.dogaAlbumTracks
  )
  const [tracks, setTracks] = useState(null)

  //fetch tracks
  useEffect(() => {
    console.log("fetching tracks")
    dispatch(fetchDogaAlbumTracks(album.id))
  }, [currentAlbum])
  //render tracks
  useEffect(() => {
    const newTracks = dogaAlbumTracks.map((track, index) => (
      <DogaTrack key={index} track={track} />
    ))
    setTracks(newTracks)
  }, [dogaAlbumTracks])

  return (
    <div>
      <h1 className="dogaAlbumTitle">{album.title}</h1>
      <img className="dogaAlbumCover" src={album.cover_art} />
      {tracks && tracks}
    </div>
  )
}

export default DogaAlbum
