import React, { useState, useEffect } from "react"
import "../../../static/css/DogaMedia.scss"
import styled, { css, keyframes } from "styled-components"
import MagImage from "../../pieces/Image.tsx"
import ReactAudioPlayer from "react-audio-player"
import AudioPlayer, {
  ActiveUI,
  InterfaceGridTemplateArea,
  PlayerPlacement,
  PlayListPlacement,
  ProgressUI,
  VolumeSliderPlacement,
} from "react-modern-audio-player"

import { useSelector } from "react-redux"

import DogaArtistList from "./DogaArtistList.js"
import DogaArtist from "./DogaArtist.js"
import DogaAlbumsList from "./DogaAlbumsList.js"
import DogaAlbum from "./DogaAlbum.js"

export default function DogaMedia(props) {
  const currentArtist = useSelector((state) => state.dogaArtists.currentArtist)
  const currentAlbum = useSelector((state) => state.dogaAlbums.currentAlbum)
  const nowPlaying = useSelector((state) => state.dogaPlayer.nowPlaying)

  const size = 450
  const hoverSize = 450 + "px"

  const [playlist, setPlaylist] = useState([
    {
      name: "",
      writer: "",
      img: "",
      src: "",
      id: 1,
    },
  ])

  useEffect(() => {
    if (nowPlaying) {
      console.log(currentArtist.name)
      let newPlaylist = [
        {
          name: nowPlaying.title,
          writer: currentArtist.name,
          img: currentAlbum.cover_art,
          src: nowPlaying.audio_file,
          id: 1,
        },
      ]
      setPlaylist(newPlaylist)
      console.log("new playlist")
    }
  }, [nowPlaying])

  return (
    <>
      <div className="dogaMediaOutsideContainer">
        {/* <MagImage
          className="dogaCover"
          height={size + "px"}
          width={size + "px"}
          hoverHeight={hoverSize}
          hoverWidth={hoverSize}
          src="https://dogamedia.s3.amazonaws.com/images/dolphin+copy+shining+stars-01.png"
        /> */}
        <p>note:this page has just begun development and is a placeholder</p>
        <DogaArtistList />
        {currentArtist && <DogaArtist artist={currentArtist} />}
        {currentArtist && <DogaAlbumsList artist={currentArtist} />}
        {currentAlbum && currentAlbum.id && <DogaAlbum album={currentAlbum} />}

        {/* having issues with the autoplay... */}
        <AudioPlayer
          playList={playlist}
          audioInitialState={{
            isPlaying: nowPlaying ? true : false,
            muted: false,
            volume: 0.8,
            curPlayId: 1,
          }}
          placement={{
            interface: {
              templateArea: {
                trackTimeDuration: "row1-5",
                progress: "row1-4",
                playButton: "row1-6",
                repeatType: "row1-7",
                volume: "row1-8",
              },
            },
            player: "bottom-left",
          }}
          activeUI={{
            all: true,
            // progress: "wave",
          }}
        />
      </div>
    </>
  )
}
