import React, { useEffect,useRef,useState } from "react"
import p5 from "p5";

import myImage from './assets/lips2.png'

  

export default function WarpSketch(props) {
    let warpRef = useRef()
    

    // SKETCH
    function sketch(p5) {
        let clicked=0;
        let matrixsize=3
        let matrix=[[-1,-1,-1],[-1,9,-1],[-1,-1,-1]]
        let img1, img2;

        p5.preload = () => {
            img1 = p5.loadImage(myImage)
        }


        p5.setup = () => {
            p5.createCanvas(500, 500);
            p5.pixelDensity(0.5)
            p5.frameRate(12)
        }
        
        p5.draw = () => {
            p5.colorMode(p5.HSB, 100);
            p5.loadPixels();
            img1.loadPixels();
            //p5.print(img1.pixels)
            let pixelMult=1
            for (let x = 0; x<p5.width*pixelMult; x++) {
                for (let y = 0; y<p5.height*pixelMult; y++) {
                    let loc1 = x+y*p5.width;
                    //to do the transformation:
                    //specify the x and y transformations
                    //loc1 is input loc in final array
                    //loc2 is the transformation loc...
                    let loc2 = p5.constrain(p5.int(x+(y+(y*p5.mouseY/300*p5.tan(p5.PI*8/y/1))+p5.random(0.999,1)*p5.sin(p5.PI+y/p5.map(p5.mouseX/7,0,p5.width,1,1000)))*p5.width), 1, p5.pixels.length-1);
                    //let loc2 = loc1;
                    //println(mouseY);
                    p5.pixels[loc1]=img1.pixels[loc2];
                }
            }

            // let d = pixelDensity();
            // for (let i = 0; i < d; i++) {
            //     for (let j = 0; j < d; j++) {
            //         // loop over
            //         index = 4 * ((y * d + j) * width * d + (x * d + i));
            //         pixels[index] = r;
            //         pixels[index+1] = g;
            //         pixels[index+2] = b;
            //         pixels[index+3] = a;
            //     }
            // }
            p5.updatePixels();
        }

    }








    useEffect(()=>{
        let myP5 = new p5(sketch, warpRef.current)
        return myP5.remove
    },[])

    return (
        <>
        <div className="warpSketch" ref={warpRef}></div>
        </>
    )
}