import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  dogaAlbums: [],
  dogaAlbumsLoaded: false,
  error: null,
  currentAlbum: {},
  dogaAlbumTracks: [],
}

//GET ARTISTS
export const fetchDogaAlbums = createAsyncThunk(
  "dogaAlbums/fetchDogaAlbums",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/label/artist/${id}/`)
      console.log(response.data)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)
export const fetchDogaAlbumTracks = createAsyncThunk(
  "dogaAlbums/fetchDogaAlbumTracks",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/label/album/${id}/`)
      console.log(response.data)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const dogaAlbumsSlice = createSlice({
  name: "dogaAlbums",
  initialState,
  reducers: {
    setCurrentAlbum: (state, action) => {
      state.currentAlbum = action.payload
    },
  },
  extraReducers: {
    [fetchDogaAlbums.pending]: (state, action) => {
      state.status = "loading"
    },
    [fetchDogaAlbums.fulfilled]: (state, action) => {
      state.status = "succeeded"
      state.dogaAlbums = action.payload
      //set Albums loaded to true so it doesn't reload in the front end again!
      state.dogaAlbumsLoaded = true
    },
    [fetchDogaAlbums.rejected]: (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    },
    [fetchDogaAlbumTracks.pending]: (state, action) => {
      state.status = "loading"
    },
    [fetchDogaAlbumTracks.fulfilled]: (state, action) => {
      state.status = "succeeded"
      state.dogaAlbumTracks = action.payload
    },
    [fetchDogaAlbumTracks.rejected]: (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    },
  },
})

export const { setCurrentAlbum } = dogaAlbumsSlice.actions

export default dogaAlbumsSlice.reducer
