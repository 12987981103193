import React, { useEffect,useRef } from "react"
import p5 from "p5";



  

export default function CoolSketch(props) {

    let myRef = useRef()
    function sketch(p5) {
        p5.setup = () => p5.createCanvas(1400, 700);
        
        p5.draw = () => {
            const loc = 130;
            const loc2 = 100;
            p5.background(255,0,0,127)
            let c = p5.color('green'); 
            p5.rectMode(p5.CORNER)
            p5.fill(c); 
            for(let x=0;x<20;x++){
                for(let y=0;y<30;y++){
                    p5.rect(x*loc*p5.mouseX/100,y*loc*p5.mouseY/75, 5, 10)

                }

            }
            for(let x=0;x<20;x++){
                for(let y=0;y<30;y++){
                    p5.rect(x*loc2*p5.mouseX/100,y*loc2*p5.mouseY/75, 1, 1)

                }

            }
        }

    }
    useEffect(()=>{
        let myP5 = new p5(sketch, myRef.current)
        return myP5.remove
    },[props.sketch])

    return (
        <>
        {/* <canvas id="p5Canvas" width="200" height="100" style={{border:'1px solid #000000'}}></canvas> */}
        <div className="coolSketch" ref={myRef}></div>
        </>
    )
}