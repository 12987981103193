import React, { useEffect, useState } from "react"
import DogaAlbumCard from "./DogaAlbumCard"
import { useSelector, useDispatch } from "react-redux"

import { fetchDogaAlbums } from "../../../store/slices/dogaMedia/dogaAlbumsSlice"

function DogaAlbumList({ artist }) {
  const dispatch = useDispatch()
  const albumsLoaded = useSelector((state) => state.dogaAlbums.dogaAlbumsLoaded)
  const dogaAlbums = useSelector((state) => state.dogaAlbums.dogaAlbums)
  const [renderAlbums, setRenderAlbums] = useState(null)

  useEffect(() => {
    dispatch(fetchDogaAlbums(artist.id))
  }, [artist, dispatch])

  useEffect(() => {
    const newAlbumsRender = dogaAlbums.map((album, index) => (
      <DogaAlbumCard id={index} album={album} />
    ))
    setRenderAlbums(newAlbumsRender)
  }, [dogaAlbums])

  return (
    <>
      <div className="dogaAlbumListContainer">
        {renderAlbums && <>{renderAlbums}</>}
      </div>
    </>
  )
}

export default DogaAlbumList
