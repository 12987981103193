import React, { Component, useState, useEffect } from "react"
import Iframe from "react-iframe"
import "../../static/css/Release.css"
import Track from "./Track"
import Video from "./Video"
import MagImage from "./Image.tsx"
import ArtText from "./PSketch/ArtText"
import { BiCool } from "react-icons/bi"
import { MdPlaylistAddCircle } from "react-icons/md"
import { PiHeartStraightDuotone as Heart } from "react-icons/pi"
import { BsFillPlayFill as Play } from "react-icons/bs"
import { ImSpotify as Spotify } from "react-icons/im"
import { SiBandcamp as Bandcamp } from "react-icons/si"

import { useSelector, useDispatch } from "react-redux"
import {
  setNowPlayingData,
  addToPlaylist,
  setNowPlayingArtist,
  setNowPlayingRelease,
  reducePlaylist,
} from "../../store/slices/playerSlice"
import { addFavoriteRelease } from "../../store/slices/favoriteReleasesNewSlice"
import { addToQueue, reduceQueue } from "../../store/slices/trackQueueSlice"

export default function Release(props) {
  const [genres, setGenres] = useState(null)
  const [styles, setStyles] = useState(null)
  const [tracklist, setTracklist] = useState(null)
  const [videoArray, setVideoArray] = useState(null)
  const [images, setImages] = useState(null)
  const [RYMSearch, setRYMSearch] = useState("")
  const [spotifySearch, setSpotifySearch] = useState("")

  const nowPlayingVideoData = useSelector(
    (state) => state.player.nowPlayingVideoData
  )
  const nowPlayingRelease = useSelector(
    (state) => state.player.nowPlayingRelease
  )
  const nowPlayingArtist = useSelector((state) => state.player.nowPlayingArtist)

  const videoPlaylist = useSelector((state) => state.player.videoPlaylist)
  const dispatch = useDispatch()
  // useEffect(()=>{
  //     console.log(videoPlaylist)
  // },[videoPlaylist])

  function getImages() {
    let size = "120px"
    if (props.data.images) {
      const newImages = props.data.images.map((image) => (
        <MagImage
          className="releaseImage"
          width={size}
          height={size}
          hoverWidth="500px"
          hoverHeight="500px"
          src={image.resource_url}
        />
      ))
      setImages(newImages)
    }
  }

  function getGenres() {
    console.log(props.data)
    if (props.data.genres) {
      const newGenres = props.data.genres.map((genre) => <>{genre}</>)
      setGenres(newGenres)
    }
  }
  function getStyles() {
    if (props.data.styles) {
      const newStyles = props.data.styles.map((style) => <> / {style}</>)
      setStyles(newStyles)
    }
  }

  //pass this directly into the getTracklist function to use the "index" as the value passed in.
  function trackStyle(trackIndex) {
    return {
      display: "block",
      marginLeft: trackIndex * 5 + "px",
    }
  }
  function getTracklist() {
    if (props.data.tracklist) {
      //pass artists_sort to track. if "various" we print artist for each track
      const newTracklist = props.data.tracklist.map((track, index) => (
        <Track
          key={index}
          trackStyle={trackStyle(index)}
          handleGetTrackArtist={handleGetTrackArtist}
          positionStyle={{ color: randomHex(), backgroundColor: randomHex() }}
          sort={props.data.artists_sort}
          trackArtists={track.artists}
          title={track.title}
          duration={track.duration}
          position={track.position}
          releaseArtist={props.data.artists[0].name}
        />
      ))
      setTracklist(newTracklist)
    }
  }

  //set the automatic RYM search
  function getRYMSearch(artist, title) {
    const query = artist + " " + title
    const RYMquery = query.split(" ").join("%20")
    const finalSearch =
      "https://rateyourmusic.com/search?searchterm=" +
      RYMquery +
      "&searchtype=l"
    setRYMSearch(finalSearch)
  }
  useEffect(() => {
    getRYMSearch(props.data.artists[0].name, props.data.title)
  }, [props])

  useEffect(() => {
    getImages()
    getGenres()
    getStyles()
    getTracklist()
  }, [props.data])

  function setNowPlaying(video) {
    let videoObject
    videoObject = {
      uri: video.uri,
      title: video.title,
      artist: props.data.artists[0].name,
      artists_sort: props.data.artists_sort,
      main_release: props.data.main_release,
      id: props.data.id,
      releaseTitle: props.title,
      thumb: props.data.thumb,
      cover: props.data.images[0].uri150,
    }
    dispatch(setNowPlayingData(videoObject))
    dispatch(setNowPlayingRelease(props.data))
  }

  useEffect(() => {
    function renderVideoArray() {
      if (props.data && props.data.videos) {
        // was rendering videos directly using this in place of the img link: <Video video={video.uri}/>
        //now I am to render
        const newVideoArray = props.data.videos.map((video, index) => {
          const thumbnail = getYoutubeThumbnail(video.uri)
          if (video.embed) {
            return (
              <div className="videoOuterContainer">
                <MdPlaylistAddCircle
                  className="addVideoToPlaylist"
                  onClick={() => handleAddToQueue(video)}
                />
                <div className="thumbnailContainer" key={index}>
                  <img
                    className="videoThumbnail"
                    onClick={() => setNowPlaying(video)}
                    src={thumbnail}
                  />
                  <Play
                    className="playThumbnail"
                    onClick={() => setNowPlaying(video)}
                    src={thumbnail}
                  />
                  {/* <div class="thumbnail-title" onClick={() => setNowPlaying(video)}>{video.title} ({displayDuration(video.duration)})</div> */}
                </div>
              </div>
            )
          }
        })
        setVideoArray(newVideoArray)
      }
    }
    renderVideoArray()
  }, [props.data])

  function handleAddToQueue(video) {
    let videoObject
    videoObject = {
      uri: video.uri,
      title: video.title,
      artist: props.data.artists[0].name,
      artists_sort: props.data.artists_sort,
      main_release: props.data.main_release,
      id: props.data.id,
      releaseTitle: props.title,
      thumb: props.data.thumb,
      cover: props.data.images[0].uri150,
    }
    console.log(videoObject)
    dispatch(addToQueue(videoObject))
  }
  function addAllToQueue() {
    console.log("ADDING ALL TO PLAYLIST")
    if (props.data && props.data.videos) {
      props.data.videos.forEach((video) => {
        handleAddToQueue(video)
      })
    }
  }

  function displayDuration(duration) {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600)
    const mins = ~~((duration % 3600) / 60)
    const secs = ~~duration % 60
    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = ""
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "")
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "")
    ret += "" + secs
    return ret
  }

  //I totally didnt use GPT to churn this out
  function getYoutubeThumbnail(url) {
    const videoIdMatch = url.match(
      /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/))([\w-]{10,12})/
    )
    const videoId = videoIdMatch ? videoIdMatch[1] : null

    if (videoId) {
      return `https://img.youtube.com/vi/${videoId}/0.jpg`
    } else {
      return null
    }
  }

  function sendLabelData() {
    props.handleCallback(props.data.labels[0].id)
  }
  function randomHex() {
    return "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")
  }
  //send up the id of the album artist to get that artist.
  function sendAlbumArtistId() {
    props.handleAlbumArtistCallback(props.data.artists[0].id)
  }
  function handleGetTrackArtist(childData) {
    console.log("inrelease")
    props.handleTrackArtistCallback(childData)
  }

  function getSpotifySearch() {
    //console.log(props.data)
    const artist = props.data.artists[0].name
    const title = props.data.title
    const query = artist + " " + title
    const spotifyQuery = query.split(" ").join("%20")
    const finalSearch = "https://open.spotify.com/search/" + spotifyQuery
    setSpotifySearch(finalSearch)
  }
  function getBandcampSearch() {
    //console.log(props.data)
    const artist = props.data.artists[0].name
    const title = props.data.title
    const query = artist + " " + title + "+bandcamp+album"
    const finalSearch =
      "https://www.google.com/search?q=" + query.split(" ").join("+")
    return finalSearch
  }
  useEffect(() => {
    getSpotifySearch()
  }, [props])

  const handleAddFavoriteRelease = () => {
    console.log("adding fav")
    console.log(props.data)
    const newFavoriteRelease = {
      artist: props.data.artists[0].name,
      main_release: props.data.main_release,
      id: props.data.id,
      title: props.title,
      thumb: props.data.thumb,
    }
    dispatch(addFavoriteRelease(newFavoriteRelease))
  }

  return (
    <div className="releaseSection" data={props.releaseData}>
      {/* MAGIMAGES */}
      <div className="releaseImagesContainer">{images}</div>

      {/* RELEASEHEADER */}
      <div className="releaseHeader">
        {/* <ArtText text={props.title}/> */}
        <a class="releaseTitle" href={props.data.uri} target="_blank">
          {props.title}{" "}
        </a>
        <div class="releaseArtists" onClick={sendAlbumArtistId}>
          ({props.data.artists[0].name})
        </div>
        <a className="RYMSearchTrack" href={RYMSearch} target="_blank">
          <BiCool />
        </a>
        <a
          className="spotifySearchRelease"
          href={spotifySearch}
          target="_blank"
        >
          <Spotify />
        </a>
        <a
          className="bandcampSearchRelease"
          href={getBandcampSearch()}
          target="_blank"
        >
          <Bandcamp />
        </a>
      </div>

      <div className="releaseDataContainer">
        <div className="releaseData">
          <div className="releaseGenres">
            {genres} {styles}
          </div>
          <div className="releaseDateCountry">
            <div className="releaseDate">{props.date}</div>
            <div className="releaseCountry">{props.data.country}</div>
            {/* use label to call handler to change to that label */}
            {props.data.labels && (
              <div className="releaseLabel" onClick={sendLabelData}>
                {props.data.labels[0].name}
              </div>
            )}
            {props.data.formats && (
              <div className="releaseFormat"> {props.data.formats[0].name}</div>
            )}
          </div>
        </div>
        {/* add favorite */}
        <Heart
          className="addFavoriteRelease"
          onClick={handleAddFavoriteRelease}
        />
      </div>

      {props.data.notes && (
        <div className="releaseNotes">{props.data.notes}</div>
      )}

      {/* videos */}
      {props.data.videos && (
        <div class="releaseVideos">
          {videoArray}
          <button className="addAllToPlaylist" onClick={addAllToQueue}>
            ++all
          </button>
        </div>
      )}

      <div className="tracklistContainer">{tracklist}</div>
    </div>
  )
}
