import React, { Component, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  setNowPlayingData,
  addToPlaylist,
  setNowPlayingArtist,
  setNowPlayingRelease,
  reducePlaylist,
} from "../../store/slices/playerSlice"

import { deleteAllFavoriteTracks } from "../../store/slices/favoriteTracksSlice"

export default function FavoriteTracks(props) {
  //favorites, localstorage
  const [showFavorites, setShowFavorites] = useState(false)
  const favoriteTracksNew = useSelector((state) => state.favoriteTracksNew)
  const { favoriteTracks, error } = favoriteTracksNew

  const nowPlayingVideoData = useSelector(
    (state) => state.player.nowPlayingVideoData
  )
  const dispatch = useDispatch()

  function handleDeleteFavoriteTracks() {
    dispatch(deleteAllFavoriteTracks())
  }

  const downloadFavoriteTracks = () => {
    const storedFavorites = localStorage.getItem("favoriteTracks")
    const blob = new Blob([storedFavorites], { type: "text/plain" })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.style.display = "none"
    a.href = url
    a.download = "favoriteTracks.txt"
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  //when favorite track in list is clicked
  //sets nowPlayingVideoData using dispatch function to the data from the object passed to it
  //the data passed to it is the data format of a "video" which is how the favoriteTrack is stored when created
  // not sure how "now playing release" will be set yet.
  function setNowPlaying(video) {
    let videoObject
    videoObject = {
      uri: video.uri,
      title: video.title,
      artist: video.artist,
      artists_sort: video.artists_sort,
      main_release: video.main_release,
      id: video.release_id,
      releaseTitle: video.releaseTitle,
      thumb: video.thumb,
      cover: video.cover,
    }
    dispatch(setNowPlayingData(videoObject))
    //dispatch(setNowPlayingRelease(props.data))
  }

  // const deleteFavorites = () => {
  //     const updatedFavorites = [];
  //     setFavorites(updatedFavorites);
  //     localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
  // };

  // const toggleFavorites = () =>{
  //     setShowFavorites(!showFavorites)
  // }
  // const downloadFavorites = () => {
  //     const storedFavorites = localStorage.getItem("favorites");
  //     const blob = new Blob([storedFavorites], { type: "text/plain" });
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.style.display = "none";
  //     a.href = url;
  //     a.download = "favorites.txt";
  //     document.body.appendChild(a);
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //     document.body.removeChild(a);
  // };

  return (
    <>
      {favoriteTracks && favoriteTracks[0] && (
        <>
          {favoriteTracks.map((fav, index) => (
            <div
              onClick={() => setNowPlaying(fav)}
              className="favoriteTrack"
              key={index}
            >
              <img className="favoriteTrackThumbnail" src={fav.thumb} />
              {fav.artist} - {fav.title}
            </div>
          ))}

          {/* <button onClick={handleDeleteFavoriteTracks}>DeleteAll</button>
          <button onClick={downloadFavoriteTracks}>Download</button> */}
        </>
      )}
    </>
  )
}
