import React, { Component, useState, useEffect } from "react"
import { render } from "react-dom"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Routes, Route, Link } from "react-router-dom"

import "bootstrap/dist/css/bootstrap.css"
import axios from "axios"

import "../static/css/App.scss"
import Discogs from "./pages/Discogs"
import DogaMedia from "./pages/DogaMedia/DogaMedia"
import Login from "./pages/Login.js"
//using mixed typescript/javascript in Art... for styled sheets.
//added stuff  to babel, webpack per gpt
import Art from "./pages/Art"
import Music from "./pages/Music"
import Settings from "./pieces/Settings"
import WebFont from "webfontloader"

import SiteTitleSketch from "./pieces/PSketch/SiteTitleSketch"

import { useSelector, useDispatch } from "react-redux"
import store from "../store/store"
import { setDogaMedia } from "../store/slices/dogaMediaSlice.js"

import { logout } from "../store/slices/user/userActions"
import { logoutTracks } from "../store/slices/favoriteTracksNewSlice"
import { logoutReleases } from "../store/slices/favoriteReleasesNewSlice"

export default function App() {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  //GLOBAL STYLE STATE AND SETTINGS
  const bodyColor = useSelector((state) => state.settings.bodyColor)

  useEffect(() => {
    console.log("new bodycolor:" + bodyColor)
    document.body.style = "background:" + String(bodyColor)
  }, [bodyColor])

  const logoutHandler = () => {
    dispatch(logout())
    dispatch(logoutTracks())
    dispatch(logoutReleases())
  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Droid Sans",
          "Tilt Neon",
          "Gloria Hallelujah",
          "Varela Round",
          "Russo One",
          "Concert One",
          "Play",
          "Libre Barcode 39 Extended Text",
          "Nabla",
          "Foldit",
          "Foldit:wght@800",
          "Sarina",
        ],
      },
    })
  }, [])

  return (
    <BrowserRouter>
      <div className="fullAppContainer">
        <div className="appNavContainer">
          {/* <nav> */}
          <Link className="headerNavLink" id="dogamedia" to="/label">
            <SiteTitleSketch className="siteTitleSketch" />
          </Link>
          <Link className="headerNavLink" id="diskographies" to="/">
            DISKOGRAPHIES
          </Link>
          <Link className="headerNavLink" id="art" to="/art">
            ART
          </Link>

          {userInfo ? (
            <>
              <div
                className="headerNavLink"
                id="logout"
                onClick={logoutHandler}
              >
                Logout
              </div>
              <Settings className="headerNavLink" id="settings">
                Settings
              </Settings>
            </>
          ) : (
            <Link className="headerNavLink" id="login" to="/login">
              Login
            </Link>
          )}
          {/* <Link className="headerNavLink" to="/music">MUSIC</Link> */}
          {/* </nav> */}

          {/* <Player /> */}
        </div>
        <Routes>
          <Route path="/label" element={<DogaMedia />} />
          <Route path="/" element={<Discogs />} />
          <Route path="/art" element={<Art />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/music" element={<Music />}/> */}
        </Routes>
      </div>
    </BrowserRouter>
  )
}

// ReactDOM
//   .createRoot(document.getElementById('app'))
//   .render(<App />);

// this is the way as of oct 28 2023, i changed ot import to index.js to use provider
// const appDiv = document.getElementById("root")
// render(<App />,appDiv)
