import React, { Component, useState, useEffect,useRef } from "react";


export default function MagImage({
    src,
    width,
    height,
    magnifierHeight = 500,
    magnifieWidth = 500,
    zoomLevel = 3,
    className,
    hoverWidth,
    hoverHeight,
  }: {
    src: string;
    width: string;
    height: string;
    magnifierHeight?: number;
    magnifieWidth?: number;
    zoomLevel?: number;
    className: string;
    hoverWidth: string;
    hoverHeight: string;
  }){



    const [showMagnifier, setShowMagnifier] = useState(false);
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);

    const[enlarged,setEnlarged]=useState(false)
    //strings
    const [currentWidth,setCurrentWidth] = useState(width);
    const [currentHeight,setCurrentHeight] = useState(height);
    const [imgW,setImgW]=useState(0)
    const [imgH,setImgH]=useState(0)

    const[magnifierActivated,setMagnifierActivated]=useState(false)
    const [magnifierCursor,setMagnifierCursor] = useState('url(magnifier.png), zoom-in')
    const [posDifference,setPosDifference]=useState(0)
    const [cursor, setCursor] = useState(magnifierCursor);
    
    useEffect(()=>{
        if(enlarged){
            setCurrentWidth(hoverWidth)
            setCurrentHeight(hoverHeight)
            setImgW(parseInt(hoverWidth))
            setImgH(parseInt(hoverHeight))
        }else{
            setCurrentWidth(width)
            setCurrentHeight(height)
            setImgW(parseInt(width))
            setImgH(parseInt(height))
        }
        setPosDifference(parseInt(hoverWidth)-parseInt(width))
    },[enlarged])

    function makeLarger(){
        if(!enlarged){setEnlarged(true)}
    }
    function makeSmaller(){
        if(enlarged){setEnlarged(false)}
    }
    function activateMagnifier(){
        setMagnifierActivated(!magnifierActivated)
        changeCursor()
        //console.log(magnifierActivated)
    }
    const changeCursor = () => {
        setCursor(prevState => {
          if(prevState === magnifierCursor){
            return 'none';
          }
          return magnifierCursor;
        });
    }

    return (
        <>   
            <div
            className={className}
            style={{
                position: "relative",
                height: currentWidth,
                width: currentHeight,
                cursor:cursor,
            }}
            >
            <img
                className="magImage"
                src={src}
                style={{ height: currentHeight, width: currentWidth, transition:"1s", }}
                alt={"img"}
                onClick={activateMagnifier}
                onMouseEnter={(e) => {
                    makeLarger()
                    // update image size and turn-on magnifier
                    const elem = e.currentTarget;
                    const { width, height } = elem.getBoundingClientRect();
                    setSize([imgW,imgH]);
                    setShowMagnifier(true);
                    
                    
                }}
                onMouseLeave={() => {
                    makeSmaller()
                    setShowMagnifier(false);
                }}
                onMouseMove={(e) => {
                    // update cursor position
                    
                    const elem = e.currentTarget;
                    const { top, left } = elem.getBoundingClientRect();
          
                    // calculate cursor position on the image
                    const x = e.pageX - left - window.scrollX;
                    const y = e.pageY - top - window.scrollY;
                    setXY([x, y]);
                    
                }}
            />
            <div style={{
                display: (showMagnifier && magnifierActivated) ? "" : "none",
                position: "absolute",

                // prevent magnifier blocks the mousemove event of img
                pointerEvents: "none",
                zIndex:99,
                // set size of magnifier
                height: `${magnifierHeight}px`,
                width: `${magnifieWidth}px`,
                borderRadius:"14px",
                cursor:cursor,
                // move element center to cursor pos
                top: `${y - magnifierHeight / 2 -posDifference/1.5}px`,
                left: `${x - magnifieWidth / 2 }px`,
                opacity: "1", // reduce opacity so you can verify position
                border: "1px solid lightgray", // show the border of magnifier
                backgroundColor: "white",
                backgroundImage: `url('${src}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: `${imgW * zoomLevel}px ${
                    imgH * zoomLevel
                }px`,
        
                  //calculate position of zoomed image.
                backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
                backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`    
                }}
            >     
            </div>

            </div>
        </>
    )
}