import React, { Component, useState, useEffect } from "react"
import { domain } from "../../globalVariables.js"
import "../../static/css/DiscogGpt.css"
import LoadingBar from "./LoadingBar.js"

export default function DiscogGpt(props) {
  let testMessage =
    "The artist is Dub Pistols Album is Best Got Better Genre is big beat electronic dub Year 1997 Please expand my musical knowledge"
  const [gptRequest, setGptRequest] = useState(null)
  const [showGpt, setShowGpt] = useState(false)
  const [gptResponse, setGptResponse] = useState(true)
  const [showPodcast, setShowPodcast] = useState(false)
  const [podcastResponse, setPodcastResponse] = useState(null)
  const [podcastRequest, setPodcastRequest] = useState(null)
  //on getting a new release, erase all data and reset
  useEffect(() => {
    setGptResponse(null)
    setPodcastResponse(null)
    //only set these back to false if something else done came through
    if (gptResponse) {
      setShowGpt(false)
    }
    if (podcastResponse) {
      setShowPodcast(false)
    }
  }, [props])

  //set request from props. do this without needing to "show gpt" for now. keep eye on GPT usage sinec it sends for every release.
  //REVIEWBOT
  useEffect(() => {
    let title = props.data.title
    let artist = props.data.artists[0].name
    let genres = props.data.genres
    let styles = props.data.styles
    let label = props.data.labels[0].name

    let newPodcastRequest = title + " by " + artist
    newPodcastRequest = newPodcastRequest.replace(/[{()/'"}]/g, "")
    setPodcastRequest(newPodcastRequest)

    let request = "Please expand my musical knowledge"
    let additionalParams =
      " and please shake it up by incorporating interesting philosophers and history or mathematical theories or artists or avant garde theories and deep insights fom critical theory and analysis, also dont be afraid to consider references to literature both high classic literature and popular or referring to films and weaving them into your writing"

    let newGptRequest =
      "the artist is " +
      artist +
      " the album is " +
      title +
      " the genres are " +
      genres +
      " " +
      styles +
      " the label is " +
      label +
      " the year of release is " +
      props.data.year +
      " " +
      request
    //remove bad chars
    newGptRequest = newGptRequest.replace(/[{()/'"}]/g, "")
    //console.log(newGptRequest)
    setGptRequest(newGptRequest)
  }, [props])

  //call gpt
  useEffect(() => {
    //console.log(props.data)
    if (showGpt) {
      function getGptResponse(input) {
        fetch(domain + "api/gpt/" + encodeURIComponent(input))
          .then((response) => response.json())
          .then((data) => {
            //console.log(data);
            let formattedText = formatText(data.message)
            setGptResponse(formattedText)
          })
      }
      getGptResponse(gptRequest)
    }
  }, [gptRequest, showGpt])

  function gptToggle() {
    //if a response is already shown, we need to erase it and set to not show
    if (gptResponse && showGpt) {
      setGptResponse(null)
    }
    setShowGpt(!showGpt)
  }
  //function to format GPT responses.
  function formatText(input) {
    const text = input.split("\n").map((str, index, array) => (
      <span key={index}>
        {str}
        {index === array.length - 1 ? null : <br />}
      </span>
    ))
    return <p className="gptResponse">{text}</p>
  }

  //GET PODCAST
  useEffect(() => {
    function getPodcast(input) {
      fetch(domain + "api/gpt-podcast/" + encodeURIComponent(input))
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          setPodcastResponse(data)
        })
    }

    if (showPodcast) {
      getPodcast(podcastRequest)
    }
  }, [showPodcast])
  function podcastToggle() {
    //if a response is already shown, we need to erase it and set to not show
    if (podcastResponse && showPodcast) {
      setPodcastResponse(null)
    }
    setShowPodcast(!showGpt)
  }

  return (
    <div className="discogGpt">
      {/* for now I commented this out to send a new build to server. */}
      {!showPodcast && !showGpt && (
        <>
          <button className="podcastButton" onClick={podcastToggle}>
            GENERATE AUDIO PODCAST
          </button>
          <button className="gptButton" onClick={gptToggle}>
            GENERATE GPT REVIEW
          </button>
        </>
      )}
      {showPodcast && (
        <div>
          {podcastResponse ? (
            <>
              <audio
                src={podcastResponse.urlResponse}
                controls
                autoPlay
              ></audio>
              <h3>generated in: {podcastResponse.duration}</h3>
            </>
          ) : (
            <>
              <LoadingBar />
              <h3>(podcast may take up to 3 minutes to generate)</h3>
              <p>
                (do not change page or use player while generating. i know, i'm
                working on it.)
              </p>
            </>
          )}
        </div>
      )}

      {showGpt && (
        <div>
          {gptResponse ? (
            <>{gptResponse}</>
          ) : (
            <div class="center">
              <div className="loadContainer">
                <div className="waveContainer">
                  <div className="wave"></div>
                  <div className="wave">L</div>
                  <div className="wave">o</div>
                  <div className="wave">a</div>
                  <div className="wave">d</div>
                  <div className="wave">i</div>
                  <div className="wave">n</div>
                  <div className="wave">g</div>
                  <div className="wave">.</div>
                  <div className="wave">.</div>
                  <div className="wave">.</div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
