import { createSlice } from '@reduxjs/toolkit'

export const favoriteTracksSlice = createSlice({
  name: 'favoriteTracks',
  initialState: {
    list:[],
  },


//   favoriteTracks={
//     id: ,
//     title:video.title,
//     artist:props.data.artists[0].name,
//     artists_sort:props.data.artists_sort,
//     main_release:props.data.main_release,
//     id:props.data.id,
//     releaseTitle:props.title,
//     thumb:props.data.thumb,
// }
  reducers: {
    addFavoriteTrack: (state, action) => {
        //adds an object of type "releaseFavorite"
        let newFavoriteTrack = action.payload
        let updatedFavoriteTracks = Array.isArray(state.list)
          ? [...state.list, newFavoriteTrack]
          : [newFavoriteTrack];
        localStorage.setItem("favoriteTracks", JSON.stringify(updatedFavoriteTracks));
        state.list = updatedFavoriteTracks
        console.log(updatedFavoriteTracks)
    },
    deleteAllFavoriteTracks: (state, action)=>{
      state.list=[]
      localStorage.setItem("favoriteTracks", JSON.stringify([]));
    }
  }
})

// Action creators are generated for each case reducer function
export const { addFavoriteTrack, deleteAllFavoriteTracks,

} = favoriteTracksSlice.actions

export default favoriteTracksSlice.reducer