import { createSlice } from "@reduxjs/toolkit"

export const dogaPlayerSlice = createSlice({
  name: "dogaPlayer",
  initialState: {
    nowPlaying: null,
  },
  reducers: {
    setNowPlaying: (state, action) => {
      console.log(action.payload)
      state.nowPlaying = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setNowPlaying } = dogaPlayerSlice.actions

export default dogaPlayerSlice.reducer
