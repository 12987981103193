import React, { Component, useState, useEffect } from "react"
import { render } from "react-dom"
import axios from "axios"
import Release from "../pieces/Release.js"
import Video from "../pieces/Video.js"
import Label from "../pieces/Label.js"
//env imports domain
import { domain } from "../../globalVariables.js"
import "../../static/css/DiscogRandomizer.scss"
import WebFont from "webfontloader"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import DiscogGpt from "../pieces/DiscogGpt.js"
import ReleaseList from "../pieces/ReleaseList.js"
import LoadingBar from "../pieces/LoadingBar.js"
import Player from "../pieces/Player.tsx"
import YouTube, { YouTubeProps } from "react-youtube"
import DogaMedia from "./DogaMedia/DogaMedia.js"

import { useSelector, useDispatch } from "react-redux"
import {
  setNowPlaying,
  setNowPlayingData,
  setNowPlayingRelease,
} from "../../store/slices/playerSlice"
import { setDogaMedia } from "../../store/slices/dogaMediaSlice.js"
import Favorites from "../pieces/Favorites.js"

export default function Discogs() {
  const dogaMedia = useSelector((state) => state.dogaMedia.dogaMedia)
  //learning redux
  const dispatch = useDispatch()
  const nowPlayingVideoData = useSelector(
    (state) => state.player.nowPlayingVideoData
  )
  const nowPlayingRelease = useSelector(
    (state) => state.player.nowPlayingRelease
  )

  useEffect(() => {}, [])

  const [artistData, setArtistData] = useState(null)
  const [randomId, setRandomId] = useState(randomNumber(1000))

  const [releasesData, setReleasesData] = useState(null)
  const [fullReleasesData, setFullReleasesData] = useState(null)
  const [loadingReleases, setLoadingReleases] = useState(false)

  const [releaseData, setReleaseData] = useState(null)
  const [randomRelease, setRandomRelease] = useState(null)
  const [count, setCount] = useState(false)
  const [aliases, setAliases] = useState(null)
  const [members, setMembers] = useState(null)
  const [groups, setGroups] = useState(null)
  const [urls, setUrls] = useState(null)

  const [maxArtists, setMaxArtists] = useState(1000)
  const [maxReleases, setMaxReleases] = useState(1)
  const [randomLabelId, setRandomLabelId] = useState(null)
  const [labelData, setLabelData] = useState(null)
  const [labelReleases, setLabelReleases] = useState(null)
  //flip between displaying artist or label?
  const [artistOrLabel, setArtistOrLabel] = useState(true)
  const [totalReleases, setTotalReleases] = useState(null)
  const [artistImages, setArtistImages] = useState(null)
  //form search data. convoluted. too tired.
  const [artistSearch, setArtistSearch] = React.useState("")
  const [artistSearchSubmit, setArtistSearchSubmit] = React.useState("")
  const [searchReturn, setSearchReturn] = useState(null)

  const [labelSearch, setLabelSearch] = useState("")
  const [labelSearchSubmit, setLabelSearchSubmit] = useState("")
  const [labelSearchReturn, setLabelSearchReturn] = useState(null)

  //instructions modal
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  //callback to get a new release needs a special case. not the best way to do it.
  const [releaseListId, setReleaseListId] = useState(null)

  //only show main releases?
  const [onlyMain, setOnlyMain] = useState(true)

  useEffect(() => {
    function getArtistById(id) {
      console.log("FIRING GET ARTIST")

      //null the release/releases data to start over
      setReleaseData(null)
      setReleasesData(null)
      fetch(domain + "api/artist/" + id)
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          if (data.message === "Artist not found.") {
            randomizeId(maxArtists)
            //setArtistData(oldData=>({name:"Artist not found"}))
          } else {
            setArtistData(data)
          }
        })
    }
    getArtistById(randomId)
    //set artist images
  }, [randomId])

  useEffect(() => {
    if (artistData && artistData.aliases) {
      const newAliases = artistData.aliases.map((alias) => (
        <div className="alias" onClick={() => setRandomId(alias.id)}>
          {alias.name}
        </div>
      ))
      setAliases((oldAliases) => newAliases)
    } else {
      setAliases(null)
    }
    if (artistData && artistData.members) {
      const newMembers = artistData.members.map((member) => (
        <div className="member" onClick={() => setRandomId(member.id)}>
          {member.name}
        </div>
      ))
      setMembers((oldMembers) => newMembers)
    } else {
      setMembers(null)
    }
    if (artistData && artistData.groups) {
      const newGroups = artistData.groups.map((group) => (
        <div className="group" onClick={() => setRandomId(group.id)}>
          {group.name}
        </div>
      ))
      setGroups((oldGroups) => newGroups)
    } else {
      setGroups(null)
    }
    if (artistData && artistData.urls) {
      const newUrls = artistData.urls.map((url) => {
        const lowerCaseUrl = url.toLowerCase()
        let urlId = "noUrlId"
        if (lowerCaseUrl.includes("bandcamp")) {
          urlId = "bandcampUrl"
        } else if (lowerCaseUrl.includes("soundcloud")) {
          urlId = "soundcloudUrl"
        }
        return (
          <a className="artistUrl" id={urlId} href={url} target="_none">
            {url}
          </a>
        )
      })
      setUrls((oldUrls) => newUrls)
    } else {
      setUrls(null)
    }
  }, [artistData])

  //we will fetch releases for either an artist, or a label
  //stupid, but pass in both random artist id ("id") and random labelid to function.
  //i know this is convoluted. just want to get it running.
  useEffect(() => {
    function getReleasesById(id, labelid) {
      console.log("FIRING GET RELEASESSS")
      setLoadingReleases(true)
      let req = ""
      if (artistOrLabel) {
        req = domain + "api/releases/all/" + id
      } else {
        req = domain + "api/label/releases/all/" + labelid
      }
      fetch(req)
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          if (data.message === "Artist not found.") {
            setReleasesData((oldData) => "no releases")
          } else {
            let releases
            //i think python parsed labels wrong or something. look into it. for now:
            //if lable releases from that api above, parse before passing on
            if (!artistOrLabel) {
              releases = data.releases
              setFullReleasesData(data.releases)
              console.log("SUCCESSFULLY GOT RELEASES?")
              console.log(releases)
            } else {
              releases = data.releases
              setFullReleasesData(data.releases)
            }
            //we set two variables. one for FULL releases, one for RELEASES. releases is displayed. full is stored.
            //if only main is activated, set releases to only show those
            if (onlyMain && artistOrLabel) {
              releases = releases.filter((release) => release.role === "Main")
            }
            //console.log(releases)
            setReleasesData(releases)
            setMaxReleases(releases.length)
            setTotalReleases(releases.length)
            setLoadingReleases(false)

            //end by randomizing a release automatically???
            //this has been causing some problems.
            randomizeRelease(releases.length)
          }
        })
    }
    if (artistData || randomLabelId) {
      getReleasesById(randomId, randomLabelId)
      //put get images here since it will be in time
      getArtistImages()
    }
  }, [artistData, randomLabelId])

  //filter if only main checked.
  //this way we do not trigger entire process again
  useEffect(() => {
    let releases
    if (releasesData) {
      console.log("FIRING ONLY MAIN")
      if (onlyMain) {
        releases = releasesData.filter((release) => release.role === "Main")
      } else if (fullReleasesData) {
        releases = fullReleasesData
      }
      setReleasesData(releases)
      setMaxReleases(releases.length)
      setTotalReleases(releases.length)
      //randomizeRelease(releases.length)
    }
  }, [onlyMain])

  useEffect(() => {
    function getReleaseById(randomNumber) {
      console.log("FIRING GET RELEASE")
      let releaseId = ""
      //convoluted.
      //checks if release List id is available. from release list.
      //randomizing release nulls this value, which is the usual case.
      //then checks if we are in artist or labe lmode.
      //hits api accordingly.
      if (releaseListId) {
        releaseId = releaseListId
      } else if (releasesData && releasesData[randomRelease]) {
        console.log("data to search:")
        //console.log(releasesData)
        //console.log(releasesData[randomRelease])
        if (releasesData[randomRelease].main_release) {
          releaseId = releasesData[randomRelease].main_release
        } else {
          releaseId = releasesData[randomRelease].id
        }
      }
      //after establishing the releaseId we search it
      fetch(domain + "api/release/" + releaseId)
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
          if (data.message === "Artist not found.") {
            setReleaseData(null)
          } else {
            const release = data
            //console.log(release)
            setReleaseData(release)
            //console.log(releasesData)
          }
        })
    }
    if ((artistData || labelData) && releasesData) {
      getReleaseById(randomRelease)
    }
  }, [randomRelease, releaseListId])
  //when click a release set randomrelease to the id to fetch.
  function getRelease(id) {
    //console.log(id)
    setRandomRelease((prev) => id)
  }

  useEffect(() => {
    if (!artistOrLabel) {
      function getLabelById(labelid) {
        fetch(domain + "api/label/" + labelid)
          .then((response) => response.json())
          .then((data) => {
            //console.log(data);
            if (data.message === "Label not found.") {
              setLabelData(null)
            } else {
              const label = data
              //console.log(release)
              setLabelData(label)
              //console.log(labelData)
            }
          })
      }
      getLabelById(randomLabelId)
      //console.log('fired effect')
    }
  }, [artistOrLabel, randomLabelId])

  //search artist from form.
  useEffect(() => {
    //make sure in artist mode
    setArtistOrLabel(true)
    function searchArtistName(artist_query) {
      console.log("searching for:" + artist_query)
      fetch(domain + "api/search/artist/" + artist_query)
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
          if (data.message === "not found.") {
          } else {
            const artist = data
            //console.log(release)
            // setArtistData(artist)
            //console.log(artistData)
            if (data.id) {
              setSearchReturn(artist)
              setRandomId(data.id)
            } else {
              setSearchReturn({ id: 1 })
              setRandomId(1234)
            }
          }
        })
    }
    if (artistSearch) {
      searchArtistName(artistSearch)
    }
  }, [artistSearchSubmit])
  //grab new data based on searchReturn
  // useEffect(() => {
  //     function getReleasesByIdSearch(){
  //         let req=""
  //         if(searchReturn && searchReturn.id){
  //             //reset artist view
  //             const id = searchReturn.id
  //             if(!artistOrLabel){setArtistOrLabel(prev=>!prev)}
  //             req=domain+"api/releases/all/"+id
  //             fetch(req)
  //             .then(response => response.json())
  //             .then(data => {
  //                 //console.log(data);
  //                 if(data.message==="Artist not found."){
  //                     setReleasesData(oldData=>"no releases")
  //                 }
  //                 else{
  //                     //set releases accordingly
  //                     const releases = data.releases
  //                     console.log(data.releases)
  //                     setReleasesData(releases)
  //                     setMaxReleases(data.releases.length)
  //                     setTotalReleases(data.releases.length)
  //                 }
  //             })
  //         }
  //     }
  //     if(searchReturn){
  //         getReleasesByIdSearch()
  //         randomizeRelease(maxReleases)
  //         //put get images here since it will be in time
  //         getArtistImages()

  //     }
  // }, [searchReturn]);

  // label searching
  // useEffect(()=>{
  //     function searchLabelName(label_query){
  //         console.log("searching for:" + label_query)
  //         fetch(domain+"api/search/label/"+label_query)
  //         .then(response => response.json())
  //         .then(data => {
  //             console.log(data);
  //             if(data.message==="not found."){
  //             }
  //             else{
  //                 const label = data
  //                 console.log(data)
  //                 setLabelData(label)
  //                 //console.log(labelData)
  //                 if(data.results){
  //                     setLabelSearchReturn(label)
  //                 }else{
  //                     setLabelSearchReturn({results:null})
  //                 }
  //             }
  //         })
  //     }
  //     searchLabelName(labelSearch)
  // },[labelSearchSubmit]);

  function getArtistImages() {
    if (artistData && artistData.images) {
      const newImages = artistData.images.map((image) => (
        <img className="artistImage" src={image.resource_url} />
      ))
      setArtistImages(newImages)
    }
  }

  //get data from form
  function handleSearchChange(event) {
    setArtistSearch((prev) => event.target.value)
    //console.log(artistSearch)
  }
  //submit data
  function handleArtistSearch(event) {
    event.preventDefault()
    setArtistSearchSubmit((prev) => artistSearch)
    //console.log(artistSearchSubmit)
  }
  // label search following aboe models
  function handleLabelSearchChange(event) {
    setLabelSearch((prev) => event.target.value)
  }
  function handleLabelSearch(event) {
    event.preventDefault()
    setLabelSearchSubmit((prev) => labelSearch)
    //console.log(labelSearchSubmit)
  }

  function randomNumber(max) {
    const min = Math.ceil(1)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min) + min)
  }
  function randomizeId(max) {
    const min = Math.ceil(1)
    max = Math.floor(max)
    const newId = Math.floor(Math.random() * (max - min) + min)
    setRandomId((oldId) => newId)
  }
  function randomizeRelease(max) {
    //"setReleaseListId(null)" is used here
    //to ensure that the Release-getter skips it.
    //and follows the normal procedure of pulling random releases
    //rather than the id that was clicked on in the "full release list"
    setReleaseListId(null)
    const min = Math.ceil(0)
    max = Math.floor(max)
    const newRelease = Math.floor(Math.random() * (max - min) + min)
    setRandomRelease(newRelease)
  }
  function randomizeLabelId(max) {
    const min = Math.ceil(1)
    max = Math.floor(max)
    const newId = Math.floor(Math.random() * (max - min) + min)
    setRandomLabelId((oldId) => newId)
  }

  // SAVE AND LOAD MAX ARTISTS SETTING FROM STORAGE
  function handleMaxArtists(e) {
    setMaxArtists(e.target.value)
    localStorage.setItem("maxArtists", e.target.value)
  }
  useEffect(() => {
    const storedRandom = localStorage.getItem("maxArtists")
    if (storedRandom) {
      setMaxArtists(JSON.parse(storedRandom))
    }
  }, [])

  // we want an individual function for get label, get artist. to flip state between them.
  function getRandomLabel(id) {
    // null the release data to start
    setReleaseData(null)
    // false means it is set to show label in the html
    if (artistOrLabel) {
      setArtistOrLabel((prev) => !prev)
    }
    randomizeLabelId(id)
    setArtistData(null)
    setReleaseData(null)
  }
  function getRandomArtist(id) {
    // false means it is set to show label in the html
    if (!artistOrLabel) {
      setArtistOrLabel((prev) => !prev)
    }
    randomizeId(id)
  }

  //CALL BACK FUNCTIONS
  //RELEASE: GET LABEL from release callback function.
  function CallBack(childData) {
    if (artistOrLabel) {
      console.log("setting artist or label")
      setArtistOrLabel((prev) => !prev)
      console.log(artistOrLabel)
    }
    //not random. the id we search for.
    setRandomLabelId(childData)
  }
  function CallBack2(childData) {
    if (!artistOrLabel) {
      setArtistOrLabel((prev) => !prev)
    }
    //sets the randomId that triggers artist reset to the Id passed up.
    setRandomId(childData)
  }
  //callback to grab release Id from releaselist
  //sets "releaseListId" which triggers UseEffect to get new data. skips other checks
  function handleGetReleaseIdCallback(childData) {
    console.log("child data is" + childData)
    setReleaseListId(childData)
  }
  function handleTrackArtistCallback(childData) {
    setRandomId(childData)
  }

  const toggleOnlyMain = () => {
    setOnlyMain(!onlyMain)
  }

  //callback from FavoriteReleases.
  //temporary but fun
  //Favorite releases pases in favoriteRel, the object
  //this object is the same as the one in the local storage for
  //favorite releases slice but from that index.
  //sets random Id in this component.
  function handleSetReleaseId(favoriteRel) {
    //passed up from favoriteReleases->favorites>discogs.
    //should be "release_id"
    setReleaseListId(favoriteRel.release_id)
  }

  return (
    <>
      {dogaMedia ? (
        <DogaMedia />
      ) : (
        <>
          <div className="discogsRandomizerOuterContainer">
            <>
              <Favorites setReleaseId={handleSetReleaseId} />
            </>

            <div className="discogsInputs">
              <input
                value={maxArtists}
                className="randomSizeInput"
                type="text"
                placeholder="enter maximum random id"
                onChange={handleMaxArtists}
              />
              <button
                className="randomizerButton"
                onClick={() => getRandomArtist(maxArtists)}
              >
                RANDOM ARTIST
              </button>
              <button
                className="randomizerButton"
                id="randomRelease"
                onClick={() => randomizeRelease(maxReleases)}
              >
                RANDOM RELEASE
              </button>
              <button
                className="randomizerButton"
                onClick={() => getRandomLabel(20000)}
              >
                RANDOM LABEL
              </button>
              {/* artist search */}
              <form className="artistSearchForm" onSubmit={handleArtistSearch}>
                <input
                  className="artistSearchInput"
                  type="text"
                  // onKeyDown={checkSubmit}
                  placeholder="search artist"
                  onChange={handleSearchChange}
                  name="artistSearch"
                  value={artistSearch}
                />
              </form>
              {/* <form className="labelSearchForm" onSubmit={handleLabelSearch}>
                <input
                    className="labelSearchSubmit"
                    type="text"
                    // onKeyDown={checkSubmit}
                    placeholder="search label"
                    onChange={handleLabelSearchChange}
                    name="labelSearch"
                    value={labelSearch}
                />
            </form> */}

              {/* INSTRUCTIONS */}
              <Button
                className="instructionsModal"
                variant="primary"
                onClick={handleShow}
              >
                instructions
              </Button>
              <Modal show={show} onHide={handleClose}>
                <Modal.Header className="instructionsHeader" closeButton>
                  <Modal.Title>HOW TO USE THIS APP!</Modal.Title>
                </Modal.Header>
                <Modal.Body className="instructionsModalText">
                  This app uses Discogs.com API and is designed to allow you to
                  explore discographies of artists like at a record store. This
                  project is not affiliated with Discogs.com at all.
                  <br></br>
                  The number in the first form can be left unchanged, but it
                  refers to the amount of "Discog IDs" that will be queried when
                  you click "random artist"
                  <br></br>
                  When the default number is kept, only the first 1000 artists
                  that were added to Discogs.com Database will be included in
                  the pool of random artists
                  <br></br>
                  Increasing this number up to 4,000,000 will allow for more
                  results, but I suggest keeping it beneath 5-10K to start. You
                  will get many good results.
                  <br></br>
                  Once an Artist is loaded in, you can either click "Random
                  album" to pull up a random album from them, or click "show
                  releases" to browse to one and load it.
                  <br></br>
                  Note: by default you filter only the "main releases" but
                  clicking the checkbox allows for all appearances (think
                  compilation albums and various odds and ends) that the artist
                  appears on to be included in the pool.
                  <br></br>
                  Due to extremely large discographies for people like Mozart or
                  Beatles, they may take a while to load, or lead to API
                  timeouts with things not loading in properly for now.
                  <br></br>
                  You can click "++All" to load any available Youtube audio into
                  the player. It will add to a playlist. You may add more as you
                  browse to other albums, and I suggest activating shuffle.
                  <br></br>
                  You may also click around on the label name beneath the album
                  release to switch to "Label" mode and load an entire Label in
                  place of an Artist, to explore this way.
                  <br></br>
                  Lastly, you can search for a specific artist in the "search
                  artist" box.
                  <br></br>
                  NOTE: The "random label" and "search artist" features may
                  introduce bugs where things load incorrectly. Reload if this
                  happens for now.
                  <br></br>
                  Lastly, you can use the GPT features on any album for fun.
                  These are new and experimental and may not always work as
                  expected. Do not change the page, it may take a few minutes to
                  generate. The Podcast generator has not been fully tested and
                  has issues where it stops loading due to some issue in the app
                  state that I haven't had time to fix.
                  <br></br>
                  ENJOY EXPLORING AND FINDING CRAZY OBSCURE STUFF AND DIVING
                  INTO RARE BURIED GEMS OF YOUR FAVORITE ARTISTS AND ENJOYING
                  THE ALBUM ART!
                  <br></br>
                  Also, consider navigating to the album on Discogs by clicking
                  it, and adding it to your wishlist, or navigating to youtube
                  by clicking the video in the player to add it to your personal
                  playlists on Youtube to save.
                </Modal.Body>
                <Modal.Footer className="instructionsFooter">
                  <Button
                    className="modalCloseButton"
                    variant="secondary"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>

            {/* switch between label and artist mode. */}

            {artistOrLabel ? (
              <>
                {artistData && (
                  <div className="artistSection">
                    {/* <h3>id: {randomId} </h3> */}
                    <div className="artistHeaderContainer">
                      <a
                        className="artistName"
                        href={artistData.uri}
                        target="_blank"
                      >
                        {artistData.name}
                      </a>
                      {artistData.images && artistImages}
                      <div className="artistRealName">
                        ({artistData.realname})
                      </div>

                      {urls && <div className="urlsContainer">{urls}</div>}
                      {/* aliases etc */}
                      {members && (
                        <div className="membersContainer">
                          <div className="members">members: </div>{" "}
                          <div className="membersList">{members}</div>
                        </div>
                      )}
                      {aliases && (
                        <div className="aliasesContainer">
                          <div className="aka">aliases: </div>{" "}
                          <div className="artistAliases">{aliases}</div>
                        </div>
                      )}
                      {groups && (
                        <div className="groupsContainer">
                          <div className="inGroups">in groups: </div>{" "}
                          <div className="groupsList">{groups}</div>
                        </div>
                      )}
                    </div>

                    <div className="catalogDisplay">
                      <div className="totalReleasesLabel">
                        catalog-size: {totalReleases}
                      </div>
                      {releasesData && (
                        <ReleaseList
                          handleGetReleaseId={handleGetReleaseIdCallback}
                          handleTrackArtistCallback={handleTrackArtistCallback}
                          releases={releasesData}
                        />
                      )}
                      <input
                        className="css-checkbox"
                        name="cssCheckbox"
                        type="checkbox"
                        id="showMain"
                        onChange={toggleOnlyMain}
                        defaultChecked={true}
                      />

                      <label id="onlyMainLabel" for="showMain">
                        Filter main releases
                      </label>
                    </div>

                    <div className="artistProfile">
                      {artistData.profile && artistData.profile}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {labelData && (
                  <Label
                    handleGetReleaseId={handleGetReleaseIdCallback}
                    data={labelData}
                    releases={releasesData}
                    totalReleases={totalReleases}
                  />
                )}
              </>
            )}

            {releaseData ? (
              <div>
                <Release
                  data={releaseData}
                  title={releaseData.title}
                  date={releaseData.year}
                  // terrible naming for these but whatever. callback1 sends label, callback2 sends album artist to get new artist
                  handleCallback={CallBack}
                  handleAlbumArtistCallback={CallBack2}
                  handleTrackArtistCallback={handleTrackArtistCallback}
                />
              </div>
            ) : (
              <>{loadingReleases && <LoadingBar />}</>
            )}
          </div>

          {releaseData && <DiscogGpt data={releaseData} />}

          <div className="spaceAtBottomOfDiscogs"></div>

          <Player
            releaseData={nowPlayingRelease}
            data={nowPlayingVideoData}
            handleGetReleaseId={handleGetReleaseIdCallback}
          />
        </>
      )}
    </>
  )
}
