import { createSlice } from "@reduxjs/toolkit"

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    bodyColor: "#27D0F6",
  },
  reducers: {
    setBodyColor: (state, action) => {
      state.bodyColor = action.payload
      console.log("inreducer" + state.bodyColor)
    },
  },
})

// Action creators are generated for each case reducer function
export const { setBodyColor } = settingsSlice.actions

export default settingsSlice.reducer
