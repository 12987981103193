import react, {useEffect, useState, useRef} from 'react'
import '../../static/css/ReleaseList.css'

export default function ReleaseList(props){

    const[releases,setReleases]=useState(null)
    const[showReleases,setShowReleases]=useState(false)
    const menuRef = useRef(null)


    useEffect(()=>{
        getReleases()
    },[props.releases])

    const releaseStyling = {
        color: "white",
        backgroundColor: "DodgerBlue",
        padding: "10px",
        fontFamily: "Arial"
      };


    function getReleases(){
        if(props.releases){
            const newReleases = props.releases.map((release) => <div onClick={()=>sendIdUp(release)} className="releaseInList"><span className="releaseListYear">{release.year}</span><div className="releaseRole" id={release.role==="Main" ? "mainRelease": "otherRelease"}>{release.role}</div>{release.thumb&&<img className="releaseThumb" src={release.thumb}></img>}{release.artist} - {release.title} {(release.catno) && <div className="releaseCatno">[ {release.catno} ]</div>}</div>);
            setReleases(newReleases)
        }
    }

    //only should set to true now since i made the one below for clicking outside and dont want to retrigger
    function toggleShowReleases(){
        setShowReleases(!showReleases)
    }

    //clicking
    // document.addEventListener('mousepressed',closeWhenClickOutside)
    // function closeWhenClickOutside(e){
    //     if(menuRef.current && showReleases && !menuRef.current.contains(e.target)){
    //         setShowReleases(false)
    //       }
    // }

    //close when mouse leave
    // if(showReleases){
    //     document.getElementById("releaseDisplay").addEventListener('mouseleave',closeWhenMouseLeave)
    // }
    // function closeWhenMouseLeave(e){
    //     if(menuRef.current && showReleases && !menuRef.current.contains(e.target)){
    //         setShowReleases(false)
    //       }
    // }

    function sendIdUp(release){
        //console.log(release)
        console.log("release that is being sent up")
        console.log(release)
        if(release.main_release){props.handleGetReleaseId(release.main_release)}else{props.handleGetReleaseId(release.id)}
        //since clicked, dont show.
        setShowReleases(false)
    }


    return(
        <>  
        
            {releases&&<div className="releaseList">
                <div className="showHideReleaseList" onClick={toggleShowReleases}>show releases</div>
                {showReleases&& <div ref={menuRef} className="releasesDisplayContainer" id="releaseDisplay" onMouseLeave={toggleShowReleases} >{releases}</div>}
            </div>}
        
        </>
    )
}