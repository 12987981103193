import React, { useEffect, useState } from "react"
import "../../static/css/Player.scss"
import Video from "./Video"
import YouTube, { YouTubeProps, YouTubePlayer } from "react-youtube"
import TrackQueue from "./TrackQueue"
import { useSelector, useDispatch } from "react-redux"
import { ThunkDispatch } from "@reduxjs/toolkit"
import {
  setNowPlaying,
  setNowPlayingData,
  reducePlaylist,
  removeItemFromPlaylist,
} from "../../store/slices/playerSlice"
import {
  reduceQueue,
  addToQueue,
  removeItemFromQueue,
} from "../../store/slices/trackQueueSlice"

//import { addFavoriteTrack } from "../../store/slices/favoriteTracksSlice"
import { HiMiniPlayPause } from "react-icons/hi2"
import { BsSkipForward } from "react-icons/bs"
import { SlMinus as Minus } from "react-icons/sl"
import { BsFillPlayFill as PlayItem } from "react-icons/bs"
import { PiHeartStraightDuotone as Heart } from "react-icons/pi"
import { GoUnmute as Unmuted } from "react-icons/go"
import { GoMute as Muted } from "react-icons/go"
import { FaShuffle as Shuffle } from "react-icons/fa6"

import { addFavoriteTrack } from "../../store/slices/favoriteTracksNewSlice"

//defining types... see gpts advice because YAWN
interface PlayerState {
  nowPlayingVideoUrl: string | null
  nowPlayingVideoData: any
  videoPlaylist: any[]
}
interface FavoriteTracksState {
  list: any[]
}
interface TrackQueueState {
  videoList: any[]
}

interface RootState {
  player: PlayerState
  trackQueue: TrackQueueState
  favoriteTracks: FavoriteTracksState
}

export default function Player(props) {
  const [pausePlay, setPausePlay] = useState(false)
  const [player, setPlayer] = useState<YouTubePlayer>()

  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)

  const [muted, setMuted] = useState(false)
  const [currentVolume, setCurrentVolume] = useState(50)
  const [volumeBeforeMute, setVolumeBeforeMute] = useState(50)
  const [shuffleOn, setShuffleOn] = useState(false)

  const nowPlayingData = useSelector(
    (state: RootState) => state.player.nowPlayingVideoData
  )
  const dispatch = useDispatch()
  const thunkDispatch = useDispatch<ThunkDispatch<any, any, any>>()

  const videoList = useSelector(
    (state: RootState) => state.trackQueue.videoList
  )
  const [playlistRender, setPlaylistRender] = useState(null)

  useEffect(() => {}, [props])

  const youtubeOptions: YouTubeProps["opts"] = {
    height: "40",
    width: "40",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 1,
      playsinline: 1,
    },
  }

  function onPlayerReady(e) {
    setPlayer(e.target)
    setDuration(e.target.getDuration())
    e.target.playVideo()
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      if (player) {
        const time = await player.getCurrentTime()
        setCurrentTime(time)
      }
    }, 1000) // Update every second, but you can adjust this based on your needs

    // Cleanup: Clear the interval when the component unmounts
    return () => clearInterval(interval)
  }, [player])

  async function togglePlay() {
    try {
      const playerState = await player?.getPlayerState()
      if (playerState === 1) {
        // if the video is currently playing
        player?.pauseVideo()
      } else if (playerState === 2 || playerState === 5 || playerState === -1) {
        // if the video is paused, cued, or unstarted
        player?.playVideo()
      }
    } catch (error) {
      console.error("Error toggling play state:", error)
    }
  }

  async function skipTrack() {
    try {
      player?.seekTo(duration)
    } catch (error) {
      console.error("Error toggling play state:", error)
    }
  }

  async function setVideoVolume(e) {
    try {
      player?.setVolume(e.target.value)
      setCurrentVolume(e.target.value)
    } catch (error) {
      console.error("Error toggling play state:", error)
    }
  }

  async function seek(e) {
    try {
      player?.seekTo(e.target.value)
    } catch (error) {
      console.error("Error toggling play state:", error)
    }
  }

  //if items added to list and nothing playing, play them
  useEffect(() => {
    if (videoList) {
      if (!nowPlayingData) {
        dispatch(setNowPlayingData(videoList[0]))
        dispatch(reduceQueue(""))
      }
    }
  }, [videoList])

  //on ending, goes to playlist object and pops video from ending.
  function getNextVideo() {
    if (shuffleOn) {
      //if shuffling, return random index from videoList
      //set as next track, set playing remove from playlist
      if (videoList && videoList[0]) {
        const queueIndex = Math.floor(Math.random() * videoList.length)
        console.log("shuffling" + queueIndex)
        dispatch(setNowPlayingData(null))
        dispatch(setNowPlayingData(videoList[queueIndex]))
        dispatch(removeItemFromQueue(queueIndex))
      }
    } else {
      dispatch(setNowPlayingData(null))
      dispatch(setNowPlayingData(videoList[0]))
      dispatch(reduceQueue(""))
    }
  }

  function sendReleaseId(input) {
    if (input.main_release) {
      props.handleGetReleaseId(input.main_release)
    } else {
      props.handleGetReleaseId(input.id)
    }
  }

  function handleSetFavoriteTrack() {
    thunkDispatch(addFavoriteTrack(nowPlayingData))
  }

  function toggleMute() {
    if (muted) {
      player?.unMute()
      setCurrentVolume(volumeBeforeMute)
    } else {
      setVolumeBeforeMute(currentVolume)
      player?.mute()
      setCurrentVolume(0)
    }
    setMuted(!muted)
  }
  function toggleShuffle() {
    setShuffleOn(!shuffleOn)
  }

  function handleVideoNotFound() {
    getNextVideo()
  }

  return (
    <>
      <div className="playerComponent">
        <div className="playerContainer">
          <div className="player">
            <>
              <div
                className="nowPlayingInfo"
                onClick={() => sendReleaseId(nowPlayingData)}
              >
                {props.data && (
                  <img
                    className="nowPlayingAlbumArt"
                    src={
                      nowPlayingData &&
                      nowPlayingData.cover &&
                      nowPlayingData.cover
                    }
                  />
                )}

                <div className="nowPlayingText">
                  <div className="nowPlayingRelease">
                    <div className="nowPlayingVideoTitle">
                      {props.data ? props.data.title : "Nothing playing"}
                    </div>
                    <div className="nowPlayingArtist">
                      {nowPlayingData &&
                        nowPlayingData.artist &&
                        nowPlayingData.artist}{" "}
                    </div>
                    <div className="nowPlayingRelease">
                      {nowPlayingData &&
                        nowPlayingData.releaseTitle &&
                        nowPlayingData.releaseTitle}
                    </div>
                  </div>
                </div>
              </div>

              <Heart
                className="addFavoriteTrack"
                onClick={handleSetFavoriteTrack}
              />

              <div className="playerControls">
                <div className="playerTimeDisplay">
                  {timeFormat(currentTime)}{" "}
                  <div className="durationDisplay">{timeFormat(duration)}</div>
                </div>
                {props.data && props.data.uri && (
                  <YouTube
                    id="nowPlayingVideo"
                    videoId={extractYouTubeId(props.data.uri)}
                    opts={youtubeOptions}
                    onReady={(e) => onPlayerReady(e)}
                    onEnd={getNextVideo}
                    onError={handleVideoNotFound}
                  />
                )}
                <input
                  className="seekControl"
                  type="range"
                  min="0"
                  max={duration}
                  step="1"
                  value={currentTime}
                  onChange={(e) => seek(e)}
                />
                <div className="pausePlayControl">
                  <HiMiniPlayPause onClick={togglePlay} />
                </div>
                <BsSkipForward className="skipControl" onClick={skipTrack} />
                <div className="muteIcons">
                  {muted ? (
                    <Muted onClick={toggleMute} />
                  ) : (
                    <Unmuted onClick={toggleMute} />
                  )}
                </div>
                <input
                  className="volumeControl"
                  type="range"
                  min="0"
                  max="100"
                  step="0.05"
                  value={currentVolume}
                  onChange={(e) => setVideoVolume(e)}
                />
              </div>

              <div className="otherControls">
                <div onClick={toggleShuffle}>
                  {shuffleOn ? (
                    <Shuffle className="shuffle shuffle-on" />
                  ) : (
                    <Shuffle className="shuffle" />
                  )}
                </div>
              </div>
            </>
          </div>

          <TrackQueue />
        </div>
      </div>
    </>
  )

  function extractYouTubeId(url) {
    const regex = /(?:v=|\/(?:embed\/|v\/)|\.be\/)([^&\?]+)/
    const matches = url.match(regex)
    return matches ? matches[1] : null
  }
  function timeFormat(duration) {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600)
    const mins = ~~((duration % 3600) / 60)
    const secs = ~~duration % 60
    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = ""
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "")
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "")
    ret += "" + secs
    return ret
  }
  function getYoutubeThumbnail(url) {
    const videoIdMatch = url.match(
      /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/))([\w-]{10,12})/
    )
    const videoId = videoIdMatch ? videoIdMatch[1] : null

    if (videoId) {
      return `https://img.youtube.com/vi/${videoId}/0.jpg`
    } else {
      return null
    }
  }
}
