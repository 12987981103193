import React, { useEffect, useState } from "react"
import "../../static/css/Settings.scss"

import { ChromePicker } from "react-color"
import { useDispatch } from "react-redux"

import { setBodyColor } from "../../store/slices/settingsSlice"

export default function Settings(props) {
  const [showSettings, setShowSettings] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState("#fff")
  const dispatch = useDispatch()
  const handleChangeComplete = (color) => {
    setBackgroundColor(color.hex)
    dispatch(setBodyColor(color.hex))
  }

  return (
    <>
      <div
        className="headerNavLink"
        id="settings"
        onClick={() => setShowSettings(!showSettings)}
      >
        Settings
      </div>
      {showSettings && (
        <div className="settingsContainer">
          <div className="settingsDropDown">
            set background color:
            <ChromePicker
              color={backgroundColor}
              onChangeComplete={handleChangeComplete}
            />
          </div>
        </div>
      )}
    </>
  )
}
