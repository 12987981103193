import React, { useState, useEffect } from "react"
import { Link, useNavigate, uesParams, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { Form, Button, Row, Col } from "react-bootstrap"
import FormContainer from "../pieces/FormContainer.js"

import { login } from "../../store/slices/user/userActions.js"
import { fetchFavoriteTracks } from "../../store/slices/favoriteTracksNewSlice"
import { fetchFavoriteReleases } from "../../store/slices/favoriteReleasesNewSlice"

import "../../static/css/LoginRegistration.scss"

function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const dispatch = useDispatch()

  const location = useLocation()
  let navigate = useNavigate()

  const redirect = location.state ? Number(location.state) : "/diskographies"

  const userLogin = useSelector((state) => state.userLogin)
  const { error, loading, userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      navigate(redirect)
    }
    //if we get the user info, get the new favorite tracks
    dispatch(fetchFavoriteTracks())
    dispatch(fetchFavoriteReleases())
  }, [navigate, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }

  return (
    <FormContainer>
      <h1>sign in</h1>
      <p>
        registration has not been added quite yet. when you sign up you can add
        favorites and change personal settings:
      </p>
      <img
        className="loginGif"
        src="https://dogamedia.s3.amazonaws.com/variousAssets/DOGAMEDIA+REGISTRATION+PAGE.gif"
      />

      {error && <h2>{error}</h2>}
      {loading && <h2>Loading</h2>}

      <Form onSubmit={submitHandler}>
        <Form.Group controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Button type="submit" variant="primary">
          Sign in
        </Button>
      </Form>
    </FormContainer>
  )
}

export default Login
