import React from "react"
import { useEffect, useRef, createRef, useState } from "react"; 
//import p5 from "p5"
import { ReactP5Wrapper, Sketch } from "@p5-wrapper/react";
//import '../../static/css/App.css'
import GridSketch from "../pieces/PSketch/GridSketch";
import CoolSketch from "../pieces/PSketch/CoolSketch";
import P5Sketch from "../pieces/PSketch/P5Sketch";
import WarpSketch from "../pieces/PSketch/WarpSketch";
// css
import '../../static/css/Art.css';


export default function Art(props){

    const [gridNumber,setGridNumber]=useState(20)

    useEffect(()=>{

    },[])

    function handleRange(e){
        setGridNumber(e.target.value)
    }




    
    
    

        


    return (
        <>
        <div className="artPageContainer">
       
        <CoolSketch/>
        <WarpSketch id="warpSketch"/>
        <div className="artPageLink">YO YO YO</div>
        
        {/* <input type="range" min="1" max="100" onChange={handleRange} ></input>
        <GridSketch width={300} height={300} gridNumber={gridNumber}/> */}
        </div>
        </>
    )
}


