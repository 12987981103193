import React, { Component, useState, useEffect } from "react";
import '../../static/css/Release.css';
import {FaYoutube} from 'react-icons/fa'
import { PiYoutubeLogo,PiSpotifyLogoDuotone} from 'react-icons/pi'
import {AiFillApple}from 'react-icons/ai'

export default function Track(props) {
    const[duration,setDuration]=useState("")
    const[trackArtist,setTrackArtist]=useState('')
    const[youtubeSearch,setYoutubeSearch]=useState('')
    const[spotifySearch,setSpotifySearch]=useState('')
    const[appleSearch,setAppleSearch]=useState('')
    const[artistQuery,setArtistQuery]=useState('')
    
    function getTrackDuration(){
        if(props.duration){
           setDuration("- "+props.duration)
        }
    }
    //sets html AND query data
    function getTrackArtist(){
        // can set below to restrict to only showing track artists sometimes.
        // if(props.sort === "Various"){
        if(props.trackArtists && props.trackArtists[0]){
            //setTrackArtist("["+props.trackArtists[0].name+"]")
            setTrackArtist(" "+props.trackArtists[0].name+" - ")
        }else{
            setTrackArtist("")
        }
        //set search strings, add track by track artists if available.
        //dont check various b/c it might not have them
    }
    //updateArtistQuery
    useEffect(()=>{
        if(props.trackArtists && props.trackArtists[0]){
            const newQuery=props.trackArtists[0].name+' '+props.releaseArtist
            setArtistQuery(newQuery)
        }else{
            setArtistQuery(props.releaseArtist)
        }
    },[props])

    useEffect(() => {
        getTrackDuration()
        getTrackArtist()
    }, [props]);
    useEffect(()=>{
        getYoutubeSearch(artistQuery,props.title)
        getSpotifySearch(artistQuery,props.title)
        getAppleSearch(artistQuery,props.title)
    },[artistQuery,props])

    //if user clicks track name they search youtube
    function getYoutubeSearch(artist,title){
        const query=artist+" "+title
        const youtubeQuery=query.split(' ').join('+')
        const finalSearch = "https://www.youtube.com/results?search_query="+youtubeQuery
        setYoutubeSearch(finalSearch)
    }
    function getSpotifySearch(artist,title){
        const query=artist+" "+title
        const spotifyQuery=query.split(' ').join('%20')
        const finalSearch = "https://open.spotify.com/search/"+spotifyQuery
        setSpotifySearch(finalSearch)
    }
    function getAppleSearch(artist,title){
        const query=artist+" "+title
        setAppleSearch("https://music.apple.com/us/search?term="+query.split(' ').join('%20'))
    }

    function sendTrackArtistUp(){
        //console.log(release)
        console.log(props.trackArtists)
        if(props.trackArtists && props.trackArtists[0]){
            console.log("SENDING ID " + props.trackArtists[0].id)
            props.handleGetTrackArtist(props.trackArtists[0].id)
        }
    }

    return (
        <>
            <div className="trackContainer" style={props.trackStyle}>
                <div className="trackPos" style={props.positionStyle}>{props.position}</div><div className="track"> -<div className="trackArtist" onClick={sendTrackArtistUp}>{trackArtist}</div> {props.title} {duration}</div>
                
                <a className="youtubeSearchTrack" href={youtubeSearch} target="_blank"><FaYoutube /></a><a className="spotifySearchTrack" href={spotifySearch} target="_blank"><PiSpotifyLogoDuotone/></a><a className="appleSearchTrack" href={appleSearch} target="_blank"><AiFillApple/></a>
            </div>
        </>
    )
}