import { createSlice } from '@reduxjs/toolkit'

export const favoriteReleasesSlice = createSlice({
  name: 'favoriteReleases',
  initialState: {
    list:[],
  },
//   favoriteReleases={
//     id: ,
//     title:video.title,
//     artist:props.data.artists[0].name,
//     artists_sort:props.data.artists_sort,
//     main_release:props.data.main_release,
//     id:props.data.id,
//     releaseTitle:props.title,
//     thumb:props.data.thumb,
// }
  reducers: {
    addFavoriteRelease: (state, action) => {
        //adds an object of type "releaseFavorite"
        let newFavoriteRelease = action.payload
        let updatedFavoriteReleases = Array.isArray(state.list)
          ? [...state.list, newFavoriteRelease]
          : [newFavoriteRelease];
        localStorage.setItem("favoriteReleases", JSON.stringify(updatedFavoriteReleases));
        state.list = updatedFavoriteReleases
        console.log(updatedFavoriteReleases)
    },
    deleteAllFavoriteReleases: (state, action)=>{
      state.list=[]
      localStorage.setItem("favoriteReleases", JSON.stringify([]));
    }
  }
})

// Action creators are generated for each case reducer function
export const { addFavoriteRelease, deleteAllFavoriteReleases,

} = favoriteReleasesSlice.actions

export default favoriteReleasesSlice.reducer