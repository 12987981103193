import React, {useEffect, useState} from 'react'
import '../../static/css/TrackQueue.scss';

import { useSelector, useDispatch } from 'react-redux'
import { setNowPlaying, setNowPlayingData, reducePlaylist, removeItemFromPlaylist } from '../../store/slices/playerSlice'
import { reduceQueue, addToQueue, removeItemFromQueue, removeAllItemsFromQueue } from '../../store/slices/trackQueueSlice'

import {SlMinus as Minus} from 'react-icons/sl'
import {BsFillPlayFill as PlayItem} from 'react-icons/bs'




export default function TrackQueue(props) {

    const[videoListRender,setVideoListRender]=useState(null)

    const videoList = useSelector((state) => state.trackQueue.videoList);
    const nowPlayingData = useSelector((state) => state.player.nowPlayingVideoData);
    const dispatch = useDispatch()

    useEffect(()=>{
    },[props])


    //render playlist items. test for now.
    useEffect(()=>{
        let output
        if(videoList){
            output=videoList.map((video,index)=>{
            const thumbnail = getYoutubeThumbnail(video.uri)
            const item = {video:video, index:index}
            return(
                <li className="playlistItemContainer" key={index}>
                    <div className="playListItem" onClick={()=>{playItemInPlayList(index,video)}}>
                        <PlayItem className="playItem" onClick={()=>{playItemInPlayList(index,video)}}/>
                        <img className="playlistImage" src={thumbnail}/>
                        {video.title}
                    </div>

                    <Minus className="removeItemFromPlaylist" onClick={((index)=>dispatch(removeItemFromQueue(index)))}/>
                </li>
            )
            })
            setVideoListRender(output)
        }
    },[videoList])

    //user clicks on item in playlist, sets to now playing data and removes from queue.
    //a behavior to not remove from queue would be more expected but more complicated. maybe soon.
    function playItemInPlayList(index, video){
        dispatch(setNowPlayingData(video))
        dispatch(removeItemFromQueue(index))
    }








    return (
        <>
        
            {(videoList && videoList[0]) &&
                <>

                <div className="playlistOuterContainer">
                    <div className="playlistContainer">
                        <div className="playlist">

                            <ul className="playlistRender">{videoListRender}
                            </ul>
                            
                        </div>
                    </div>
                    <button className="removeAllItemsFromQueue" onClick={()=>dispatch(removeAllItemsFromQueue())}>remove all</button>

                </div>                
                
                </>
            }
            
        </>
    )















    function extractYouTubeId(url) {
        const regex = /(?:v=|\/(?:embed\/|v\/)|\.be\/)([^&\?]+)/;
        const matches = url.match(regex);
        return matches ? matches[1] : null;
    }
    function timeFormat(duration){
        // Hours, minutes and seconds
        const hrs = ~~(duration / 3600);
        const mins = ~~((duration % 3600) / 60);
        const secs = ~~duration % 60;
        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = "";
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }
    function getYoutubeThumbnail(url){
        const videoIdMatch = url.match(/(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/))([\w-]{10,12})/);
        const videoId = videoIdMatch ? videoIdMatch[1] : null;

        if (videoId) {
            return `https://img.youtube.com/vi/${videoId}/0.jpg`;
        } else {
            return null;
        }
    }

}