import React from "react"
import { useEffect, useRef, createRef, useState } from "react"; 
import p5 from "p5";

export default function P5Sketch(props) {

    let myRef = useRef()
    // let [mySketch,setMySketch]=useState(props.sketch)

    //issues with brave adblocker fucking this up.
    useEffect(()=>{
        let myP5 = new p5(props.sketch, myRef.current)
        return myP5.remove
    },[props.sketch])
    // useEffect(()=>{
    //     let myP5 = new p5(props.sketch, myRef.current)
    //     return myP5.remove
    // },[props.sketch])

    // useEffect(()=>{
    //     let myP5 = new p5(props.sketch, document.getElementById("P5Wrapper"))
    //     return myP5.remove
    // },[])
    
    return (
        <div id="P5Wrapper" className= "processingWrapper" ref={myRef}></div>
    )
    
}