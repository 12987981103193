import { configureStore } from "@reduxjs/toolkit"
import thunk from "redux-thunk"
import counterReducer from "./slices/counterSlice"
import playerReducer from "./slices/playerSlice"
import favoriteReleasesReducer from "./slices/favoriteReleasesSlice"
import favoriteTracksReducer from "./slices/favoriteTracksSlice"
import trackQueueReducer from "./slices/trackQueueSlice"
import dogaMediaReducer from "./slices/dogaMediaSlice"
import favoriteTracksNewReducer from "./slices/favoriteTracksNewSlice"
import favoriteReleasesNewReducer from "./slices/favoriteReleasesNewSlice"
import settingsReducer from "./slices/settingsSlice"

import dogaArtistsReducer from "./slices/dogaMedia/dogaArtistsSlice"
import dogaAlbumsReducer from "./slices/dogaMedia/dogaAlbumsSlice"
import dogaPlayerSlice from "./slices/dogaMedia/dogaPlayerSlice"

//I tried this with slices, and it didn't work yet. i don't understand redux well enough to translate it over.
//import userReducer from "./slices/userSlice"
//trying with separated reducers/actions
import {
  userLoginReducer,
  userRegisterReducer,
} from "./slices/user/userReducers"

const persistedFavoriteReleases = localStorage.getItem("favoriteReleases")
const favoriteReleases = persistedFavoriteReleases
  ? JSON.parse(persistedFavoriteReleases)
  : []
const persistedFavoriteTracks = localStorage.getItem("favoriteTracks")
const favoriteTracks = persistedFavoriteTracks
  ? JSON.parse(persistedFavoriteTracks)
  : []

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null

export default configureStore({
  reducer: {
    counter: counterReducer,
    player: playerReducer,
    favoriteReleases: favoriteReleasesReducer,
    favoriteTracks: favoriteTracksReducer,
    trackQueue: trackQueueReducer,
    dogaMedia: dogaMediaReducer,
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    favoriteTracksNew: favoriteTracksNewReducer,
    favoriteReleasesNew: favoriteReleasesNewReducer,
    settings: settingsReducer,
    dogaArtists: dogaArtistsReducer,
    dogaAlbums: dogaAlbumsReducer,
    dogaPlayer: dogaPlayerSlice,
  },
  preloadedState: {
    userInfo: userInfoFromStorage,
    favoriteReleases: {
      list: favoriteReleases,
    },
    favoriteTracks: {
      list: favoriteTracks,
    },
  },
  middleware: [thunk],
})
