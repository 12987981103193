import { ReactP5Wrapper, Sketch } from "@p5-wrapper/react";
import React, {useState, useRef, useEffect} from "react"
import PSketch from "./PSketch";
import P5Sketch from "./P5Sketch";
import ToonySans from './fonts/toony_sans/ToonySans_PERSONAL_USE_ONLY.otf'
import KrifonFont from './fonts/Krifon Regular.otf'


export default function SiteTitleSketch(props) {
    const [frameRate,setFrameRate]=useState(12)
    const [animating,setAnimating]=useState(false)

    function sketch(p5) {
        let Krifon, Toony
        let clickedColor='red'
        let mouseInImage=false
        let fontColor;

        p5.setup = () => {
            Krifon = p5.loadFont(KrifonFont)
            Toony = p5.loadFont(ToonySans)
            let c = p5.createCanvas(170, 30)
            c.elt.style.borderRadius = '3px';
            //c.elt.style.border = '1px solid black';
            //frameRate=12
            p5.setFrameRate(frameRate)
        }
        p5.draw = () => {
            if(p5.mouseX<=p5.width &&p5.mouseY<=p5.height){
                mouseInImage=true
            }else{
                mouseInImage=false}
            
            if(mouseInImage){
                p5.background(255, 204, 0)
                fontColor=p5.color(p5.random(250),200,p5.random(250))
            }else{
                p5.background(33,p5.random(250),p5.random(250))
                fontColor=p5.color(100,200,300)
            }
            p5.setFrameRate(frameRate)
            title1('DOGA MEDIA')
            //trying to decide what to do, set it animating default or not. for now, default is animation.
            if(!animating){
                p5.noLoop()
            }
        }
        function title1(text='doga.media'){
            const textArray =text.split('')
            //for letter in text, display
            for(let letter =0;letter<textArray.length;letter++){
                let placementX=10 
                let placementY=20;
                let multiplier=15;
                if(Math.random() < 0.5){
                    p5.textFont(Toony)
                }else{p5.textFont(Krifon)}
                p5.fill(fontColor)
                let randomColor=p5.color(p5.random(250),200,p5.random(250))
                //p5.fill(randomColor)
                p5.strokeWeight(p5.random(2));
                p5.stroke(51);
                p5.textSize(p5.random(18,23))
                p5.textAlign(p5.CENTER, p5.CENTER);
                //p5.text(letter,placementX+multiplier*letter,placementY+multiplier*letter)
                p5.text(textArray[letter], placementX+letter*multiplier+p5.random(1),10+p5.random(3))
            }
        }
        p5.mouseClicked = () =>{
            if(p5.mouseX<=p5.width &&p5.mouseY<=p5.height){changeFrameRate()}
        }
    }
    
    function changeFrameRate(){
        setAnimating(!animating)
        //if(animating){setFrameRate(12)}else{setFrameRate(0.5)}
    }
   

    return (
        <>
        {/* <div className="siteTitleSketch"> */}
        <P5Sketch sketch={sketch}/>
        {/* </div> */}
        </>
    )
}