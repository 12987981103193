import { createSlice } from '@reduxjs/toolkit'

export const dogaMediaSlice = createSlice({
  name: 'dogaMedia',
  initialState: {
    dogaMedia:false,
  },
  reducers: {
    setDogaMedia: (state, action) => {
        state.dogaMedia = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { setDogaMedia,
} = dogaMediaSlice.actions

export default dogaMediaSlice.reducer