import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  favoriteReleases: [],
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
}

// Define the async thunk for fetching favorite tracks
export const fetchFavoriteReleases = createAsyncThunk(
  "favoriteReleases/fetchFavoriteReleases",
  async (_, { getState }) => {
    const state = getState()
    const response = await axios.get("/api/favorites/releases/", {
      headers: {
        Authorization: `Bearer ${state.userLogin.userInfo.token}`,
      },
    })
    console.log(response.data)
    return response.data
  }
)

export const addFavoriteRelease = createAsyncThunk(
  "favoriteReleases/addFavoriteRelease",
  async (releaseData, { getState }) => {
    const state = getState()
    const response = await axios.post("/api/favorites/releases/", releaseData, {
      headers: {
        Authorization: `Bearer ${state.userLogin.userInfo.token}`,
      },
    })
    return response.data
  }
)

// Create the slice
export const favoriteReleasesNewSlice = createSlice({
  name: "favoriteReleases",
  initialState,
  reducers: {
    logoutReleases: (state, action) => {
      state.favoriteReleases = []
    },
  },
  extraReducers: {
    [fetchFavoriteReleases.pending]: (state, action) => {
      state.status = "loading"
    },
    [fetchFavoriteReleases.fulfilled]: (state, action) => {
      state.status = "succeeded"
      state.favoriteReleases = action.payload
    },
    [fetchFavoriteReleases.rejected]: (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    },
    [addFavoriteRelease.pending]: (state, action) => {
      state.status = "loading"
    },
    [addFavoriteRelease.fulfilled]: (state, action) => {
      state.status = "succeeded"
      state.favoriteReleases = action.payload
    },
    [addFavoriteRelease.rejected]: (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    },
  },
})

export const { logoutReleases } = favoriteReleasesNewSlice.actions

export default favoriteReleasesNewSlice.reducer
