import React, { Component, useState } from "react";
import FavoriteReleases from "./FavoriteReleases";
import FavoriteTracks from "./FavoriteTracks";
import '../../static/css/Favorites.scss'

export default function Favorites(props){

    const [showFavorites,setShowFavorites]=useState(false)


    const toggleFavorites = () =>{
        setShowFavorites(!showFavorites)
    }



    return (
    <>

        <div className="favorites">
            <h2 className="favoritesHeadline" onClick={toggleFavorites}>Favorites</h2>
            {showFavorites && <>
            <FavoriteReleases setReleaseId={props.setReleaseId} />
            <FavoriteTracks />
            </>}
        </div>


    </>
    )
}