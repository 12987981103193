import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { setCurrentArtist } from "../../../store/slices/dogaMedia/dogaArtistsSlice"

function DogaArtistCard({ artist }) {
  const dispatch = useDispatch()
  const currentArtist = useSelector((state) => state.dogaArtists.currentArtist)

  const handleSetCurrentArtist = () => {
    console.log(artist)
    dispatch(setCurrentArtist(artist))
  }

  const styleId = () => {
    if (currentArtist === artist) {
      return "currentArtistId"
    } else {
      return "notCurrentArtist"
    }
  }

  return (
    <div
      className="dogaArtistCardContainer"
      onClick={() => handleSetCurrentArtist()}
    >
      <img className="dogaArtistCardImage" src={artist.image} />
      <h3 className="dogaArtistCardName">{artist.name}</h3>
    </div>
  )
}

export default DogaArtistCard
