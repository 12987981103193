import React from "react"
import { useSelector, useDispatch } from "react-redux"

import { setNowPlaying } from "../../../store/slices/dogaMedia/dogaPlayerSlice"
import { BsFillPlayFill as Play } from "react-icons/bs"

function DogaTrack({ track }) {
  const dispatch = useDispatch()

  const handlePlayTrack = () => {
    console.log("playing a track?")
    dispatch(setNowPlaying(track))
  }

  return (
    <div className="dogaTrackContainer" onClick={handlePlayTrack}>
      <div className="dogaTrack">
        <Play className="dogaTrackPlayIcon"></Play>
        <span className="dogaTrackPosition">
          {track.position}
          <em>.</em>
        </span>
        <span className="dogaTrackName">{track.title}</span>
        <span className="dogaTrackDuration">{track.duration}</span>
      </div>
    </div>
  )
}

export default DogaTrack
