import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  favoriteTracks: [],
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
}

// Define the async thunk for fetching favorite tracks
export const fetchFavoriteTracks = createAsyncThunk(
  "favoriteTracks/fetchFavoriteTracks",
  async (_, { getState }) => {
    const state = getState()
    const response = await axios.get("/api/favorites/tracks/", {
      headers: {
        Authorization: `Bearer ${state.userLogin.userInfo.token}`,
      },
    })
    console.log(response.data)
    return response.data
  }
)

export const addFavoriteTrack = createAsyncThunk(
  "favoriteTracks/addFavoriteTrack",
  async (trackData, { getState }) => {
    const state = getState()
    const response = await axios.post("/api/favorites/tracks/", trackData, {
      headers: {
        Authorization: `Bearer ${state.userLogin.userInfo.token}`,
      },
    })
    return response.data
  }
)

// Create the slice
export const favoriteTracksNewSlice = createSlice({
  name: "favoriteTracks",
  initialState,
  reducers: {
    logoutTracks: (state, action) => {
      state.favoriteTracks = []
    },
  },
  extraReducers: {
    [fetchFavoriteTracks.pending]: (state, action) => {
      state.status = "loading"
    },
    [fetchFavoriteTracks.fulfilled]: (state, action) => {
      state.status = "succeeded"
      state.favoriteTracks = action.payload
    },
    [fetchFavoriteTracks.rejected]: (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    },
    [addFavoriteTrack.pending]: (state, action) => {
      state.status = "loading"
    },
    [addFavoriteTrack.fulfilled]: (state, action) => {
      state.status = "succeeded"
      state.favoriteTracks = action.payload
    },
    [addFavoriteTrack.rejected]: (state, action) => {
      state.status = "failed"
      state.error = action.error.message
    },
  },
})

export const { logoutTracks } = favoriteTracksNewSlice.actions

export default favoriteTracksNewSlice.reducer
