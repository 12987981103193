import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { setCurrentAlbum } from "../../../store/slices/dogaMedia/dogaAlbumsSlice"

function DogaAlbumCard({ album }) {
  const currentAlbum = useSelector((state) => state.dogaAlbums.currentAlbum)
  const dispatch = useDispatch()

  const handleSetCurrentAlbum = () => {
    console.log(album)
    dispatch(setCurrentAlbum(album))
  }

  const styleId = () => {
    if (currentAlbum === album) {
      return "currentAlbumId"
    } else {
      return ""
    }
  }

  return (
    <div
      className="dogaAlbumCardContainer"
      onClick={() => handleSetCurrentAlbum()}
    >
      <img className="dogaAlbumCardCover" src={album.cover_art} />
      <h3 className="dogaAlbumCardTitle">{album.title}</h3>
    </div>
  )
}

export default DogaAlbumCard
