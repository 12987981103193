import { createSlice } from '@reduxjs/toolkit'

export const playerSlice = createSlice({
  name: 'player',
  initialState: {
    nowPlayingVideoUrl:null,
    nowPlayingVideoData:null,
    nowPlayingArtist:null,
    nowPlayingRelease:null,
    videoPlaylist:[],
  },
//   videoObject={
//     uri:video.uri,
//     title:video.title,
//     artist:props.data.artists[0].name,
//     artists_sort:props.data.artists_sort,
//     main_release:props.data.main_release,
//     id:props.data.id,
//     releaseTitle:props.title,
//     thumb:props.data.thumb,
// }
  reducers: {
    setNowPlaying: (state, action) => {
        state.nowPlayingVideoUrl = action.payload
    },
    setNowPlayingData: (state, action) => {
        state.nowPlayingVideoData = action.payload
    },
    setNowPlayingArtist: (state, action)=> {
      state.nowPlayingArtist = action.payload
    },
    //NOT EVEN USING THE ARTIST ONE RIGHT NOW. CAN DELETE
    setNowPlayingRelease: (state, action)=> {
      state.nowPlayingRelease = action.payload
    },
    //add a video to the array
    addToPlaylist: (state, action) => {
      console.log(action.payload)
      state.videoPlaylist.push(action.payload)
      console.log(state.videoPlaylist)
    },
    reducePlaylist: (state, action)=> {
      state.videoPlaylist.shift()
    },
    //takes index as action.payload to tell which item to remove
    removeItemFromPlaylist: (state,action)=> {
      console.log("PAYLOAD:")
      console.log(action.payload)
      state.videoPlaylist.splice(action.payload,1)
    },
  }
})

// Action creators are generated for each case reducer function
export const { setNowPlaying, setNowPlayingData, addToPlaylist, reducePlaylist,
  setNowPlayingArtist, setNowPlayingRelease, removeItemFromPlaylist,
} = playerSlice.actions

export default playerSlice.reducer