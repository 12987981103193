import React, { Component, useState, useEffect } from "react";
import '../../static/css/Label.css';
import ReleaseList from "./ReleaseList";

export default function Label(props) {
    
    const[urls,setUrls]=useState([])

    useEffect(() => {
        getUrls()

    }, [props.data]);


    function getUrls(){
        if(props.data.urls){
            //pass artists_sort to track. if "various" we print artist for each track
            const newUrls = props.data.urls.map((url) => <span className="urlSpan"><a className="labelUrl"href={url}>{url}</a></span>);
            setUrls(newUrls)
        }
    }

    function handleGetReleaseId(childData){
        props.handleGetReleaseId(childData)
    }
    

    return (
        <div className="labelSection">
                {props.data.images&&<img className="labelImage" src={props.data.images[0].uri}/>}
                <a className="labelName" href={props.data.uri}>{props.data.name}</a>
                {/* <div className="labelId">{props.data.id}</div> */}
            <div className="totalReleasesLabel">catalog-size: {props.totalReleases}</div>
            <ReleaseList releases={props.releases} showCatNo={true} handleGetReleaseId={handleGetReleaseId} />

            {props.data.profile&&<div className="labelProfile">{props.data.profile}</div>}
            <br></br>
            {props.data.contact_info&&<div className="labelContact">{props.data.contact_info}</div>}
            {/* {props.data.urls&&<>{urls}</>} */}
        </div>
    )
}